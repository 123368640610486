import React from 'react';
import Badge from '../../../../../../../components/Badge';

const getStatusMarkup = (status) => {
  let statusText = '';
  let type = '';
  
  if (status === 'New') { statusText = 'Nyinkommen'; type = 'primary' }
  if (status === 'Approved') { statusText = 'Väntar på GDPR-info'; type = 'warning'  }
  if (status === 'Complete') { statusText = 'Godkänd'; type = 'success'  }

  let statusMarkup = (<Badge type={type}>{statusText}</Badge>)

  return statusMarkup;
}

export default getStatusMarkup;
