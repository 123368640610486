import React from 'react';
import { useTranslation } from 'react-i18next';

const renderSelection = ({ input, options, meta: { touched, error, warning } }) => {
  const { t } = useTranslation('upload');
return (
  <div>
    <select {...input}>
      <option value="">{t('recipient.renderSelection.recipientDropdown')}</option>
      {options.map((recipient, index) => (
        <option value={recipient.id} key={index}>
          {recipient.name}
        </option>
      ))}
    </select>
    {touched &&
        ((error && <span className="help-text help-text--has-error">{error}</span>) ||
          (warning && <span>{warning}</span>))}
  </div>
  );
}

export default renderSelection;
