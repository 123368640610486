// TODO: Translate
export const authErrorMessages = [
  {
    errorCode: 10,
    errorMessage: 'Angivet id existerar inte',
    errorField: 'deliveryId'
  },
  {
    errorCode: 11,
    errorMessage: 'Id is empty or not present in request',
    errorField: 'deliveryId'
  },
  {
    errorCode: 12,
    errorMessage: 'Angivet id är av felaktig längd',
    errorField: 'deliveryId'
  },
  {
    errorCode: 13,
    errorMessage: 'Angivet mobilnummer matchar inte angivet leverans-id',
    errorField: 'mobileNo'
  },
  {
    errorCode: 14,
    errorMessage: 'Mobilnummer saknas.',
    errorField: 'mobileNo'
  },
  {
    errorCode: 15,
    errorMessage: 'Okänt id-prefix.',
    errorField: 'deliveryId'
  },
  /* ==================================================================================== */
  {
    errorCode: 20,
    errorMessage: 'Angiven kod är ogiltig'
  },
  {
    errorCode: 21,
    errorMessage: 'Det verkar som att något kod inte fyllts i. Vänligen korntrollera.'
  },
  {
    errorCode: 22,
    errorMessage: 'Angiven kod är för kort, koden skall bestå av exakt sex siffror.'
  },
  {
    errorCode: 23,
    errorMessage: 'Angiven kod finns inte registerad. Pröva att stänga och öppna din webbläsare och försök sedan igen. Vid fortsatta problem, kontakta leveranssupport.'
  },
  /* ==================================================================================== */
  {
    errorCode: 30,
    errorMessage: 'Filen kunde inte hittas'
  },
  {
    errorCode: 31,
    errorMessage: 'Kunde inte hitta ordern. Detta kan bero på en utgången session. Pröva att hämta en ny engångskod.'
  },
  {
    errorCode: 32,
    errorMessage: 'No files in request to upload'
  },
  {
    errorCode: 33,
    errorMessage: 'Filen har tagits bort från serven då maximala giltigheten har passerats.'
  },
  {
    errorCode: 34,
    errorMessage: 'Filen kunde inte hittas i databasen'
  },
  /* ==================================================================================== */
  {
    errorCode: 40,
    errorMessage: 'Kunde ej skicka SMS. Detta kan beror på ett felaktigt formulerat telefonnummer, eller en landskod som inte stöds för närvarande.'
  },
  /* ==================================================================================== */
  {
    errorCode: 50,
    errorMessage: 'No delivery data to save'
  },
  {
    errorCode: 51,
    errorMessage: 'Error saving new delivery'
  },
  /* ==================================================================================== */
  {
    errorCode: 99,
    errorMessage: 'Fel i kommunikation med leveranssystem. Vänligen kontakta support.'
  },
]
