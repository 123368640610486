import React from 'react';
import { ReactComponent as CheckmarkIcon } from '../../../../../../../../assets/img/CheckmarkIcon.svg';

const FormSubmitted = ({ submitSucceeded, reset, clearSingleUploadData, clearSelectedRecipients, fileProgress, clearUploadProgress }) => {
  const onReset = () => {
    reset();
    clearSingleUploadData();
    clearSelectedRecipients();
    clearUploadProgress();
  };
  
  if(submitSucceeded || fileProgress.complete) {
    return (
      <div className="result-page">
        <CheckmarkIcon />
        <p className="result-message result-message--success">Din leverans har med framgång levererats till mottagaren!</p>
        <button className="btn btn--primary" onClick={onReset}>Skapa en ny leverans</button>
      </div>
    );
  } else {
    return null;
  }
}

export default FormSubmitted;
