import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import actionCreators from '../../store/actions/auth';
import PageHeader from './PageHeader/PageHeader';
import { adminMenu } from './helpers/navigationData';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class AdminLayout extends Component {
  render() {
    const { signinUser, signoutUser, authenticated, title, back } = this.props;
    return (
      <>
        <PageHeader isAuthenticated={authenticated} signinUser={signinUser} signoutUser={signoutUser} navItems={adminMenu} title={title} backLink={back} />
        {this.props.children}
        <ToastContainer position="top-right" />
      </>
    );
  }
}

function mapStateToProps(state) {
  const { authenticated } = state.auth;
  return {
    authenticated
  };
};

export default connect(
  mapStateToProps,
  dispatch => ({
    ...bindActionCreators(actionCreators, dispatch), dispatch
  })
)(AdminLayout);

// Use named export for unconnected component (for tests)
export { AdminLayout }
