import React from 'react';
import SuppliersList from './components/SuppliersList';

const MiseGdprPrefilled = ({suppliers}) => (
  <div>
    <SuppliersList suppliers={suppliers} />
  </div>
)

export default MiseGdprPrefilled;
