//import { uniqBy } from 'lodash';

const recipientsMapper = recipients => {
  const recipientsDTO = recipients.map(recipient => {
    return {
      email: recipient.email,
      firstName: recipient.firstName,
      lastName: recipient.lastName,
      cell: recipient.mobilePhone,
      companyName: recipient.companyName,
      organizationNo: recipient.orgno,
    }
  })
  
  return recipientsDTO
  //return uniqBy(recipientsDTO, 'email');
}

export default recipientsMapper;
