import { 
  GET_DOCUMENTS_PENDING,
  GET_DOCUMENTS_FULFILLED,
  GET_DOCUMENTS_REJECTED
} from './actions'

export const reducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case GET_DOCUMENTS_PENDING:
      return {
        ...state,
        isFetching: true
      }
    case GET_DOCUMENTS_FULFILLED:
      return {
        ...state,
        isFetching: false,
        data: [...action.payload.data],
      }
      case GET_DOCUMENTS_REJECTED:
      return {
        ...state,
        isFetching: false,
        hasError: true
      }
    default:
      return state
  }
}
