import React, { Component } from 'react';

export default class renderField extends Component {

  focusInput() {
    this.input.focus();
  }

  render() {
    const {
      input, type, autoFocus, className,
      meta: { touched, error }
    } = this.props
    
    return (
      <div className="auth-input-wrapper">
        <input {...input} type={type} autoFocus={autoFocus} className={className} maxLength="1" ref={node => this.input = node} />
        {touched && error && <span>{error}</span>}
      </div>
    )
  }
}
