import React from 'react';
import CheckboxGroup from '../../../../../../../../components/FormHelpers/CheckboxGroup'
import { MdPictureAsPdf } from 'react-icons/md';

export default class SelectableCard extends CheckboxGroup {
  field = ({input, meta, options}) => {

    const {name, onChange, onBlur, onFocus} = input;
    const {touched, error} = meta;
    const inputValue = input.value;

    const checkboxes = options.map(({label, value}, index) => {

      const handleChange = (event) => {
        const arr = [...inputValue];
        if (event.target.checked) {
          arr.push(value);
        }
        else {
          arr.splice(arr.indexOf(value), 1);
        }
        onBlur(arr);
        return onChange(arr);
      };

      const checked = inputValue.includes(value);
      
      return (
        <div key={`checkbox-${index}`}>
          <input type="checkbox" id={`checkbox-${index}`} name={`${name}[${index}]`} value={value} checked={checked} onChange={handleChange} onFocus={onFocus} className="hidden-card-checkbox" />
          <label className="card card--selectable" htmlFor={`checkbox-${index}`}><MdPictureAsPdf /></label>
          <label className="card-sublabel" htmlFor={`checkbox-${index}`} >{label}</label>
        </div>
      );
    });

    return (
      <>
        {checkboxes}
        {touched && error && <p className="error">{error}</p>}
      </>
    );
  };

}
