import React from 'react';
import cssVars from 'css-vars-ponyfill';
import { Switch, Route, Redirect } from 'react-router-dom'
import Callback from './scenes/Callback/index';
import NewDelivery from './scenes/Admin/scenes/Deliveries/scenes/New';
import AdminArea from './scenes/Admin/';
import SecuredRoute from './components/SecuredRoute/SecuredRoute';
import NotFound from './scenes/NotFound';
import Verify from './scenes/Verify';
import Login from './scenes/Login';
import CustomerUpload from './scenes/Customer/scenes/Upload';
// import List from './scenes/Admin/scenes/Uploads/scenes/List/';
import Uploads from './scenes/Admin/scenes/Uploads/';
import Deliveries from './scenes/Customer/scenes/Deliveries';
import UploadConfirmation from './scenes/Customer/scenes/Upload/scenes/UploadConfirmation';
import { applyTheme } from 'mise-theme-helper';

const App = ({ theme }) => {
  cssVars();
  const myTheme = {
    "--themeColor": theme.themeColor,
    "--accentColor": theme.accentColor,
    "--actionColor": theme.actionColor,
    "--successColor": theme.successColor,
    "--dangerColor": theme.dangerColor,
  }
  applyTheme(myTheme);
  return (
    <Switch>
      <Route path='/' exact>
        <Redirect to="/delivery" />
      </Route>
      {/* Public area */}
      <Route path={'/delivery'} component={Deliveries} />
      <SecuredRoute path={'/upload'} component={CustomerUpload} scopes={[]} title="Ladda upp filer" />
      <SecuredRoute path={'/uploadconfirmed'} component={UploadConfirmation} scopes={[]} title="Ladda upp filer" />
      {/* Admin area */}
      <SecuredRoute path={'/admin'} exact component={AdminArea} scopes={['deliveries:send-to-customer', 'deliveries:receive-from-customer']} title="Admin" />
      <SecuredRoute path={'/admin/deliveries/new'} component={NewDelivery} scopes={['deliveries:send-to-customer']} title="Filleverans" />
      <SecuredRoute path={'/admin/uploads'} component={Uploads} scopes={['deliveries:receive-from-customer']} title="Kunduppladdningar" />
      {/* Misc */}
      <Route exact path='/verify' component={Verify} />
      <Route exact path="/login" component={Login} />
      <Route exact path='/callback' component={Callback} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default App;
