import axios from 'axios';
import Auth0 from 'store/actions/auth/Auth'
import { toast } from 'react-toastify';
import { goBack } from 'connected-react-router';

export const DELETE_UPLOAD = 'DELETE_UPLOAD';
export const DELETE_UPLOAD_PENDING = 'DELETE_UPLOAD_PENDING';
export const DELETE_UPLOAD_FULFILLED = 'DELETE_UPLOAD_FULFILLED';
export const DELETE_UPLOAD_REJECTED = 'DELETE_UPLOAD_REJECTED';

export const actionCreators = {
  deleteSingleUpload: (deliveryId) => async (dispatch) => {
    dispatch({
      type: DELETE_UPLOAD_PENDING,
    })
    axios.delete(`/api/admin/deliveries/fromCustomer/${deliveryId}`, {
      headers: { "Authorization": `Bearer ${Auth0.getAccessToken()}` }
    }).then( () => {
      dispatch({
        type: DELETE_UPLOAD_FULFILLED
      })
      toast.success('Leveransen har raderats');
      dispatch(goBack());
    }).catch( err => {
      dispatch({
        type: DELETE_UPLOAD_REJECTED,
        error: err
      })
      toast.error('Leveransen kunde inte raderas. Kontakta supporten om felet kvarstår.');
    })
  }
};
