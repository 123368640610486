import axios from 'axios';
import FileSaver from 'file-saver';

export const SET_DELIVERY_ID = 'SET_DELIVERY_ID';
export const FETCH_FILE = 'FETCH_FILE';
export const FETCH_FILE_FULFILLED = 'FETCH_FILE_FULFILLED';
export const FETCH_FILE_REJECTED = 'FETCH_FILE_REJECTED';
export const FETCH_FILE_LIST = 'FETCH_FILE_LIST';
export const FETCH_FILE_LIST_PENDING = 'FETCH_FILE_LIST_PENDING';
export const FETCH_FILE_LIST_FULFILLED = 'FETCH_FILE_LIST_FULFILLED';
export const FETCH_FILE_LIST_REJECTED = 'FETCH_FILE_LIST_REJECTED';
export const FETCH_FILE_REJECTED_MANUAL = 'FETCH_FILE_REJECTED_MANUAL';
export const FETCH_USER_PERMISSIONS = 'FETCH_USER_PERMISSIONS';
export const FETCH_USER_PERMISSIONS_PENDING = 'FETCH_USER_PERMISSIONS_PENDING';
export const FETCH_USER_PERMISSIONS_FULFILLED = 'FETCH_USER_PERMISSIONS_FULFILLED';
export const FETCH_USER_PERMISSIONS_REJECTED = 'FETCH_USER_PERMISSIONS_REJECTED';

const getFileListName = (input) => {
  let fileName;

  try {
    fileName = decodeURI(input.match(/; filename\*=UTF-8''(.+)$/)[1]);
  } 
  catch(err) {
    console.warn('Getting filename from headers failed. Falling back to default file name...');
    fileName = 'leveransfil.xlsx'
  }
  
  return fileName;
};

const saveFile = response => {
  FileSaver.saveAs(response.data, getFileListName(response.headers['content-disposition']));
};

export const actionCreators = {
  getFileList: deliveryId => async (dispatch) => {    
    dispatch({
      type: FETCH_FILE_LIST,
      payload: axios.get('api/files', {
        params: { id: deliveryId }
      })
    })
  },
  getFile: fileId => async (dispatch) => {    
    try {
      const response = await dispatch({
        type: FETCH_FILE,
        payload: axios.get(`api/files/${fileId}`, {
          responseType: 'blob'
        })
      })

      saveFile(response.value);
    } catch (error) {
      const reader = new FileReader();
      reader.addEventListener('loadend', (e) => {
        const result = e.srcElement.result;
        const errorObject = JSON.parse(result);

        dispatch({ type: FETCH_FILE_REJECTED_MANUAL, payload: errorObject.errorMessage })
      });

      if (error.response && error.response.data) {
        reader.readAsText(error.response.data, 'utf-8');
      }
    }

  },
  setOrderId: deliveryId => {
    return {
      type: SET_DELIVERY_ID,
      value: deliveryId
    };
  },
  getUserPermissions: (deliveryId) => async (dispatch, getState) => {
    dispatch({
      type: FETCH_USER_PERMISSIONS,
      payload: axios.get(`api/admin/deliveries/permissions/${deliveryId}`)
    })
  }
};
