import axios from 'axios';
import handleError from '../../../../../../../components/FormHelpers/handleError';

const submitForm = (values, next) => {
  const { codeInputs } = values;

  const authCode = codeInputs.map( (object) => `${object.value}` ).join('');

  return axios.post('/api/authenticate/', {
    authCode: authCode
  })
  .then( () => {
    next();
  })
  .catch( (err) => handleError(err.response) );
};

export default submitForm;
