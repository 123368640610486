import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import actionCreators from "../../store/actions/auth";

class Login extends Component {
  componentDidMount() {
    const { authenticated } = this.props;

    if (!authenticated) {
      this.props.signinUser();
    }
  }
  render() {
    const { authenticated } = this.props;

    if (authenticated) {
      return <Redirect to="/" />
    }

    return null;
  }
}

const mapStateToProps = ({ auth }) => ({
  authenticated: auth.authenticated
});

const mapActionCreators = {
  ...actionCreators,
}

export default connect(mapStateToProps, mapActionCreators)(Login);

