import React from 'react';
import { ReactComponent as CheckmarkIcon } from '../../../../../../assets/img/CheckmarkIcon.svg';
import PageHeader from '../../../../../../components/Layout/PageHeader/PageHeader';
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';

const UploadConfirmation = ({push}) => {
  const { t } = useTranslation('upload');
  return (
    <>
      <PageHeader />
      <div className="result-page">
        <CheckmarkIcon />
        <p className="result-message result-message--success">{t('uploadConfirmation.successMessage')}</p>
        <button className="btn btn--primary" onClick={ () => {push('/upload');} }>{t('uploadConfirmation.button')}</button>
      </div>
    </>
  );
}

export default connect(null, { push })(UploadConfirmation);
