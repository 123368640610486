export const customerMenu = [
  {
    label: 'getFiles',
    link: '/',
  },
  {
    label: 'uploadFiles',
    link: '/upload',
  },      
]

export const adminMenu = [
  {
    label: 'Skapa leverans',
    link: '/admin/deliveries/new',
  },
  {
    label: 'Inskickade filer',
    link: '/admin/uploads',
  },      
]
