import axios from 'axios';
import { debounce } from 'lodash';
import Auth0 from '../../../../../../../../store/actions/auth/Auth'

export const FIND_RECIPENTS = 'FIND_RECIPENTS';
export const FIND_RECIPENTS_PENDING = 'FIND_RECIPENTS_PENDING';
export const FIND_RECIPENTS_FULFILLED = 'FIND_RECIPENTS_FULFILLED';
export const FIND_RECIPENTS_REJECTED = 'FIND_RECIPENTS_REJECTED';
export const AUTOFILL_SELECTED_RECIPIENT = 'AUTOFILL_SELECTED_RECIPIENT';
export const CLEAR_RECIPIENTS = 'CLEAR_RECIPIENTS';
export const CLEAR_SELECTED_RECIPIENT = 'CLEAR_SELECTED_RECIPIENT';
export const CLEAR_SINGLE_RECIPIENT = 'CLEAR_SINGLE_RECIPIENT';

const findRecipientsActionCreator = ({ dispatch, searchPhrase }) => {
  dispatch({
    type: FIND_RECIPENTS,
    payload: axios.get(`api/admin/deliveries/searchActor?searchPhrase=${searchPhrase}`, { headers: { "Authorization": `Bearer ${Auth0.getAccessToken()}` } })
  })
}

const debouncedFindRecipients = debounce(findRecipientsActionCreator, 500)

export const actionCreators = {
  findRecipients: (searchPhrase) => async (dispatch) => {
    if (searchPhrase.length > 2) {
      debouncedFindRecipients({ dispatch, searchPhrase })
    }
    if (searchPhrase === '') {
      dispatch({
        type: CLEAR_RECIPIENTS,
      })
    }
  },
  autofillSelectedRecipients: (email, phone, index) => async (dispatch, getState) => {
   const recipient = getState().Admin.scenes.Deliveries.scenes.new.data.recipients.data.find(recipient => recipient.email === email && recipient.cell === phone)
   // const recipient = getState().Admin.scenes.Deliveries.scenes.new.data.recipients.data.find(recipient => recipient.email === email)
    dispatch({
      type: AUTOFILL_SELECTED_RECIPIENT,
      payload: { recipient, index },
    })
  },
  removeSingleRecipient: (index) => async (dispatch) => {
    dispatch({
      type: CLEAR_SINGLE_RECIPIENT,
      payload: index,
    })
  },
  clearSelectedRecipients: () => async (dispatch) => {
    dispatch({
      type: CLEAR_SELECTED_RECIPIENT,
    })
  }
};
