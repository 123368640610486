import React from 'react';
import FileList from './components/FileList';
import { getFile } from './helpers/';

const Files = ({files}) => (
  <div>
    <h3>Filer</h3>
    <FileList files={files} getFile={getFile} />
  </div>
)

export default Files;
