import axios from 'axios';
import { actionCreators as Delivery } from '../../../actions';
import handleError from '../../../../../../../components/FormHelpers/handleError';

const submitForm = (values, next, dispatch) => {
  const { deliveryId, mobileNo } = values;

  return axios.get(`/api/deliveries/${deliveryId}`, {
    params: {
      mobileNo: mobileNo
    }
  })
  .then( (response) => {
    if (!process.env.NODE_ENV || ['development', 'test'].includes(process.env.NODE_ENV)) {
      alert(response.data.testAuthCode);
    } 
 
    dispatch(Delivery.setOrderId(response.data.id));
    next();
  })
  .catch( (err) => handleError(err.response) );
};

export default submitForm;
