import axios from 'axios';
import Auth0 from './../../../../store/actions/auth/Auth'

export const GET_RECIPIENTS = 'GET_RECIPIENTS';
export const GET_RECIPIENTS_PENDING = 'GET_RECIPIENTS_PENDING';
export const GET_RECIPIENTS_FULFILLED = 'GET_RECIPIENTS_FULFILLED';
export const GET_RECIPIENTS_REJECTED = 'GET_RECIPIENTS_REJECTED'

export const actionCreators = {
  getRecipients: () => (dispatch) => {
    dispatch({
      type: GET_RECIPIENTS,
      payload: axios.get('api/admin/Auth0User/getSellers', { headers: { "Authorization": `Bearer ${Auth0.getAccessToken()}` } })
    })
  }
};
