import React from 'react';
import { DropdownContent, DropdownMenu, DropdownTrigger  } from 'react-unstyled-dropdown';
import { useTranslation } from 'react-i18next';

const AccountDropdown = props => {
  const { clientInfo, signoutUser } = props;
  const { t } = useTranslation('pageHeader');

  return (
    <>
    <DropdownMenu className="account-dropdown dropdown">
      <DropdownTrigger className="account-dropdown__trigger dropdown__trigger">
        <img src={clientInfo.picture} alt="avatar" className="account-dropdown__account-image" />
      </DropdownTrigger>
      <DropdownContent className="account-dropdown__content dropdown__content">
        <div className="account-header">
          <div className="account-header__profile">
            <img src={clientInfo.picture} alt="avatar" className="account-profile__image" />
            <div>
              <span className="account-profile__name">{clientInfo.given_name} {clientInfo.family_name}</span>
              <span className="account-profile__email">{clientInfo.email} </span>
            </div>
          </div>
          <button className="btn btn--secondary btn--block" onClick={signoutUser}>{t('logout')}</button>
        </div>
        <ul className="account-dropdown-items">
          <li className="account-dropdown-items__item">
            <a href="#" className="account-dropdown-items__link">{t('accountSettings')}</a>
          </li>
          <li className="account-dropdown-items__item">
          </li>
        </ul>
      </DropdownContent>
    </DropdownMenu>
    </>
  )
};

export default AccountDropdown;
