import React, { Component } from 'react';
import { Field } from 'redux-form';
import renderField from '../../../../../../../components/FormHelpers/renderField';

const AUTH_CODE_LENGTH = 6;

class renderCode extends Component {

  focus(index) {
    if(index < AUTH_CODE_LENGTH - 1) {
      this[`codeInputs[${index+1}]`].getRenderedComponent().focusInput();
    }
  }

  render() {
    const {
      input,
      fields,
      meta: {touched, error}
    } = this.props

    return (
      <div className="auth-row">
        {fields.map((fieldName, index) => (
          <Field
            key={index}
            name={`${fieldName}.value`}
            type="tel"
            className="form-control"
            component={renderField}
            autoFocus={index === 0}
            onChange={ () => this.focus(index) }
            withRef
            ref={node => this[fieldName] = node}
          />
        ))}
      </div>
    )
  }
}

export default renderCode;
