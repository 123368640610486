import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../data/uploads/actions';
import { actionCreators as actions } from '../../../../data/upload/actions';
// TODO: Let us refactor this so we have the data for list/show above the scenes so we can import them all at the same time!
import Gdpr from './components/Gdpr';
import Sidebar from './components/Sidebar';
import Files from './components/Files';
import LoadingScreen from '../../../../../../components/Loading';
import s from './Show.module.sass'

class Show extends React.Component {
  componentDidMount() {
    const { match: { params: { id } } } = this.props;
    this.props.getSingleUpload(id);
  }
  render() {
    const { 
      hasError,
      initialValues,
      uploadData,
      hasData,
      isFetching,
      deleteSingleUpload,
      uploadData: { status, files, timestamp, sender, recipients = [] } 
    } = this.props;
    //TODO: Handle "status":"Rejected" here so we dont display the object to used if he figures the URL-path...

    if (hasData && !isFetching) {
      return (
        <div className={s['show-upload']}>
          <main className={s['main']}>
            <Gdpr uploadData={uploadData} initialValues={initialValues} deleteSingleUpload={deleteSingleUpload} />
            {
              status === 'Complete' &&
              <Files files={files}  />
            }
          </main>
          <Sidebar recipient={recipients[0]} sender={sender} dateRecived={timestamp}  />
        </div>
      )
    }

    if (isFetching)
      return <LoadingScreen />

    if (hasError)
      return <div>Fel vid hämtning av data.</div>

    return null;
  }
}

export { Show };
export default connect(
  state => {
    const { data, isFetching, hasError, hasData } = state.Admin.data.upload;
    return {
      uploadData: data,
      isFetching: isFetching,
      hasData: hasData,
      hasError: hasError,
      initialValues: {
        gdpr: data.gdpr
      }
    }
  },
  dispatch => ({
    ...bindActionCreators({...actionCreators, ...actions}, dispatch), dispatch
  })
)(Show);
