import { 
  SET_UPLOAD_DATA,
  SET_UPLOAD_PROGRESS,
  SET_UPLOAD_STATUS,
  SET_UPLOAD_COMPLETE,
  CLEAR_UPLOAD_PROGRESS,
} from './actions'

export const reducer = (state = { complete: false }, action) => {
  switch (action.type) {
    case SET_UPLOAD_DATA:
      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name] || {},
          name: action.payload.name,
          file: action.payload.file,
          deliveryId: action.payload.deliveryId,
        }
      }
    case SET_UPLOAD_PROGRESS:
      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name] || {},
          progress: action.payload.progress,
        }
      }
    case SET_UPLOAD_STATUS:
      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name] || {},
          status: action.payload.status,
        }
      }
    case SET_UPLOAD_COMPLETE:
      return {
        ...state,
        complete: action.payload.complete,
      }
    case CLEAR_UPLOAD_PROGRESS:
      return {};
    default:
      return state
  }
}
