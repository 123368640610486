import React from 'react';
import { withRouter } from 'react-router-dom';
import { reduxForm, change } from 'redux-form';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { actionCreators as uploadActionCreators } from '../../../../data/upload/actions';
import { actionCreators as fileProgressActionCreators } from '../../../../../../data/fileProgress/actions';
import { actionCreators as suppliersActionCreators } from '../../../../data/Suppliers/actions';
import { actionCreators as recipientsActionCreators } from './data/Recipients/actions';
import { actionCreators as documentsActionCreators } from './data/Documents/actions';
import { actionCreators as deliveryMessageActionCreators } from './data/Message/actions';
import AdminLayout from '../../../../../../components/Layout/AdminLayout';
import { validate, warn } from './components/validateForm';
import FormSubmitted from './components/FormSubmitted/'
import Form from './components/Form/'

class CreateDelivery extends React.Component {
  componentDidMount() {
    this.props.clearSingleUploadData()
    
    const { search } = this.props.location;
    if (search) {
      const deliveryId = search.replace("?id=", "");
      this.props.getSingleUpload(deliveryId);
      this.props.getSuppliers();
    }
  }
  componentWillUnmount() {
    this.props.clearSingleUploadData()
    this.props.clearSelectedRecipients()
  }
  componentDidUpdate(prevProps) {
    const { selectedSuppliers, recipients } = this.props.initialValues;
    if (selectedSuppliers !== prevProps.initialValues.selectedSuppliers) {
      if (selectedSuppliers && selectedSuppliers.length >= 1) {
        this.props.selectSupplier(selectedSuppliers);
      }
    }
    if (recipients.length !== prevProps.initialValues.recipients.length) {
      this.props.dispatch(change('miseUpload', 'recipients', recipients))
    }
  }
  render() {
    return (
      <AdminLayout title="Skapa leverans">
        <Form {...this.props} />
        <FormSubmitted {...this.props} />
      </AdminLayout>
    )
  }
}

export default compose(
  withRouter,
  connect(
    state => ({
      initialValues: {
        selectedSuppliers: state.Admin.data.upload.data.selectedSuppliers ? state.Admin.data.upload.data.selectedSuppliers : null,
        recipients: [
          ...state.Admin.scenes.Deliveries.scenes.new.data.recipients.selectedRecipients.length ? 
          state.Admin.scenes.Deliveries.scenes.new.data.recipients.selectedRecipients :
          [state.Admin.data.upload.data.sender || {}]
        ],
        gdpr: state.Admin.data.upload.data.gdpr || null,
        deliveryMessage: state.Admin.scenes.Deliveries.scenes.new.data.message.data
      },
      deliveryId: state.Admin.data.upload.data.id,
      fileProgress: state.fileProgress,
      uploading: Object.values(state.fileProgress).filter(Boolean).some(({ status }) => status === 'active')
    }),
    { 
      ...uploadActionCreators,
      ...suppliersActionCreators,
      ...recipientsActionCreators,
      ...documentsActionCreators,
      ...fileProgressActionCreators,
      ...deliveryMessageActionCreators
    },
  ),
  reduxForm({
    form: 'miseUpload',
    validate,
    warn,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    updateUnregisteredFields: true,
  })
)(CreateDelivery);
