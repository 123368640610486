import React from 'react';
import { FieldArray } from 'redux-form';
import renderCode from './renderCode';
import { useTranslation } from 'react-i18next';

const Form = ({ handleSubmit, pristine, submitting, error, submitForm }) => {
  const { t } = useTranslation('deliveries');
  return (
    <form className="auth-form" onSubmit={handleSubmit(submitForm)}>
      <h3>{t('authenticate.form.header')}</h3>
      <div className="auth-form-inner">
        <span className="auth-sub-header">{t('authenticate.form.subHeader')}</span>
        <FieldArray name="codeInputs" component={renderCode} />
        <button type="submit" className="btn btn--primary btn--block" disabled={pristine || submitting}>{t('authenticate.form.button')}</button>
        {error && <span className="help-text help-text--has-error">{error}</span>}
      </div>
    </form>
  )
}

export default Form;

