import { connect } from "react-redux";
import actions from 'store/actions/auth';
import Auth from 'store/actions/auth/Auth';
import { ReactComponent as ErrorIcon } from '../../assets/img/ErrorIcon.svg';

import { Callback } from "mise-react-redux-auth0";

const handleAuthenticationCallback = (props)  => (err, result) => {
  if (err || !result) {
    return;
  } else if (result.returnUrl) {
    if (
      !result.returnUrl.includes("admin") &&
      props.Auth.hasScopes([
        "deliveries:send-to-customer",
        "deliveries:receive-from-customer"
      ])
    ) {
      props.history.push("/admin/");
    } else {
      props.history.push(result.returnUrl);
    }
  } else {
    props.history.push("/");
  }
}

function mapStateToProps(state) {
  const { error } = state.auth;
  return {
    error,
    /* non-state props */
    ErrorIcon,
    Auth,
    handleAuthenticationCallback,
  };
}

export default connect(
  mapStateToProps,
  actions,
)(Callback);
