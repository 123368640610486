import React from 'react';
import Suppliers from '../../../../../../../../components/Suppliers';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import submitForm from './helpers/submitForm';


const MiseGdprForm = ({ handleSubmit, selectedSuppliers, s }) => (
  <form className={s["mise-form-section"]} onSubmit={handleSubmit(submitForm)}>
    <h2>Adresskällor</h2>
    <Suppliers selectedSuppliers={selectedSuppliers} />
    <br />
    <button type="submit" className="btn btn--primary">Spara data</button>
  </form>
);

export default compose(
  connect(
    state => ({
      initialValues: {
        selectedSuppliers: state.Admin.data.suppliers.selectedSuppliers,
      }
    })
  ),
  reduxForm({
    form: 'MiseGdprForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    updateUnregisteredFields: true
  })
)(MiseGdprForm);
