import {
  GET_SINGLE_UPLOAD_PENDING,
  GET_SINGLE_UPLOAD_FULFILLED,
  GET_SINGLE_UPLOAD_REJECTED,
  SET_SINGLE_UPLOAD_DATA,
  CLEAR_SINGLE_UPLOAD_DATA,
} from './actions';

export const reducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case GET_SINGLE_UPLOAD_PENDING:
      return {
        ...state,
        isFetching: true
      }
    case GET_SINGLE_UPLOAD_FULFILLED:
      return {
        ...state,
        isFetching: false,
        hasData: true,
        data: action.payload.data
      }
    case GET_SINGLE_UPLOAD_REJECTED:
      return {
        ...state,
        isFetching: false,
        hasError: true
      }
    case SET_SINGLE_UPLOAD_DATA:
      return {
        ...state,
        isFetching: false,
        hasData: true,
        data: action.payload.data
      }
    case CLEAR_SINGLE_UPLOAD_DATA:
      return {
        ...state,
        data: [],
        hasData: false,
        isFetching: false,
      }
    default:
      return state
  }
};
