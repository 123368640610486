import React from 'react';
import { FieldArray } from 'redux-form';
import Select from 'react-select';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../data/Suppliers/actions';
import renderSelectedSuppliers from './components/renderSelectedSuppliers';

const Suppliers = (props) => (
  <>
    <Select
      options={props.values} // state
      onFocus={!props.hasFiles && props.getSuppliers} //fn
      onBlurResetsInput={false}
      onCloseResetsInput={false}
      isLoading={props.isFetching}
      placeholder="Klicka och välj involverade adresskällor"
      loadingMessage={ () => 'Laddar adresskällor...'}
      value={props.selectedSuppliers} // state
      onChange={props.selectSupplier} //fn
      isMulti
      isDisabled={props.hasError}
    />
    {props.hasError &&
      <div>Fel uppstod vid hämtning av leverantörer</div>
    }
    <FieldArray 
      name="selectedSuppliers" 
      component={renderSelectedSuppliers} 
      options={props.selectedSuppliers}
    />
  </>
)

export default connect(
  state => state.Admin.data.suppliers,
  dispatch => ({
    ...bindActionCreators(actionCreators, dispatch), dispatch
  })
)(Suppliers);

export { Suppliers }; // Export without connect for unit tests
