import React from 'react';
import { Modal, Button } from 'antd';
import 'antd/es/modal/style/index.css';
import { withTranslation } from 'react-i18next';

class SecurityModal extends React.Component {
  state = {
    loading: false,
    visible: false,
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  }

  handleOk = () => {
    this.setState({ visible: false });
  }

  handleCancel = () => {
    this.setState({ visible: false });
  }

  render() {
    const { t } = this.props
    const { visible, loading } = this.state;
    return (
      <div className={this.props.s['security-message__holder']}>
        <strong className={this.props.s['security-message__modal']} onClick={this.showModal}>
          {t('securityModal.modalOpen')}
        </strong>
        <Modal
          visible={visible}
          title={<strong>{t('securityModal.modalContent.title')}</strong>}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="submit" className="btn btn--primary" loading={loading} onClick={this.handleOk}>
              {t('securityModal.modalContent.button')}
            </Button>,
          ]}
        >
          <p>{t('securityModal.modalContent.firstText')}</p> <p>{t('securityModal.modalContent.secondText')}<a className={this.props.s['contact-link']} href="mailTo:leverans@marknadsinformation.se" target="_top">leverans@marknadsinformation.se</a></p>
        </Modal>
      </div>
    );
  }
}

export default withTranslation('upload')(SecurityModal);
