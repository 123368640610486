import FileSaver from 'file-saver';

const getFileListName = (input) => {
  let fileName;

  try {
    fileName = decodeURI(input.match(/; filename\*=UTF-8''(.+)$/)[1]);
  } 
  catch(err) {
    console.warn('Getting filename from headers failed. Falling back to default file name...', err);
    fileName = 'leveransfil.xlsx'
  }
  
  return fileName;
};

const saveFile = response => {
  try {
    FileSaver.saveAs(response.data, getFileListName(response.headers['content-disposition']));
  } catch(err) {
    console.error('Failed to save file', err);
  }
};

export default saveFile;
