import axios from 'axios';
import Auth0 from '../../../../../../../store/actions/auth/Auth';
import handleError from '../../../../../../../components/FormHelpers/handleError';
import { actionCreators as documentsActionCreators } from '../data/Documents/actions';

const { postFile, uploadComplete } = documentsActionCreators;

const submitForm = async (values, dispatch, props) => {
  let req1 = {}
  let client = Auth0.getClientInfo();
  const basedOn = props.deliveryId;

  const arrayToPost = {
    userFullName: client.given_name + ' ' + client.family_name,
    userEmail: client.email,
    ...basedOn && { basedOn },
    ...values
  };
  delete arrayToPost['fileDrop'];

  try {
    req1 = await axios.post(`/api/admin/deliveries/toCustomer`, {
      ...arrayToPost
    }, {
      headers: { "Authorization": `Bearer ${Auth0.getAccessToken()}` }
    })
  } catch (err) {
    return handleError(err.response);
  }

  let promises = [];

  values.fileDrop.forEach((file) => {
    promises.push(postFile(file, req1.data.deliveryId)(dispatch));
  });

  return Promise.all(promises)
    .then(() => dispatch(uploadComplete(req1.data.deliveryId)))
    .catch(err => handleError(err.response));
};

export default submitForm;
