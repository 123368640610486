import React, {useState } from 'react';
import { connect } from 'react-redux';
import { Field, FieldArray } from 'redux-form';
import horizontalFormInput from '../../../../../../../../components/FormHelpers/horizontalFormInput';
import { MdClear } from 'react-icons/md';
import { AutoComplete } from 'antd';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../data/Recipients/actions';
import 'antd/lib/select/style/index.css';

const renderSearchField = ({ 
  input, className, id, label, index, type, placeholder, isFetching, 
  helpText, disabled, recipients, findRecipients, autofillSelectedRecipients, 
  meta: { touched, error, warning }
}) => {
const [value, setValue] = useState("")

return (
  <div className='form-group form-group--horizontal'>
    <div>
      <label htmlFor={id} className="label">{label}</label>
    </div>
    <div>
      <AutoComplete
        dataSource={recipients}
        onSearch={findRecipients}
        onSelect={(e) => autofillSelectedRecipients(JSON.parse(e).email, JSON.parse(e).cell, index)}undContent={isFetching ? <div>Laddar e-postadresser...</div> : null}
        style={{ width: '100%' }}
        value={value}
        onChange={(e) => {
          e.charAt(0) === "{" ? setValue(JSON.parse(e).email) : setValue(e);
          findRecipients('');
        }}
      >
        <input {...input} placeholder={placeholder} type={type} className={`${className} ${touched && error ? 'form-control--has-error' : ''} ${warning && touched ? 'form-control--has-warning' : ''} ${!error && !error && touched ? 'form-control--has-success' : ''}`} id={id} name={id} disabled={disabled} />
      </AutoComplete>
      {touched ? (
        (error && <span className='help-text help-text--has-error'>{error}</span>) || 
        (warning && <span className="help-text help-text--has-warning">{warning}</span>)
        ): helpText && <span className="help-text">{helpText}</span>
      }
    </div>
  </div>
);
};

const renderRecipents = (
  { fields, findRecipients, autofillSelectedRecipients, removeSingleRecipient, recipients, isFetching }
) => {
  return (
    <>
      {fields.map((recipient, index) => (
        <div className="card" key={index}>
          <div className="card-header">
            <span className="card-header__title">
              Mottagare {index + 1}
              </span>
              <div className="card-header__actions">
                <button
                  className="card__action card__action--remove"
                  type="button"
                  title="Remove recipient"
                  onClick={() => {
                    removeSingleRecipient(index);
                    fields.remove(index);
                  }}
                >
                  <MdClear />
                </button>
              </div>
          </div>
          <div className="card__body form">
            <Field
              component={renderSearchField}
              className="form-control"
              label="E-postadress"
              name={`${recipient}.email`}
              findRecipients={findRecipients}
              autofillSelectedRecipients={autofillSelectedRecipients}
              isFetching={isFetching}
              recipients={recipients}
              index={index}
            />
            <Field component={horizontalFormInput} className="form-control" label="Förnamn" name={`${recipient}.firstName`}  />
            <Field component={horizontalFormInput} className="form-control" label="Efternamn" name={`${recipient}.lastName`}   />
            <Field component={horizontalFormInput} className="form-control" label="Mobilnummer" name={`${recipient}.cell`}   />
            <Field component={horizontalFormInput} className="form-control" label="Företagsnamn" name={`${recipient}.companyName`}   />
            <Field component={horizontalFormInput} className="form-control" label="Organisationsnummer" name={`${recipient}.organizationNo`}   />
          </div>
        </div>
      ))}
        <button type="button" onClick={() => fields.push({})} className="btn btn--block btn--secondary">
          &#43; Lägg till mottagare
      </button>
    </>
  )}
;

const Recipients = (props) => (
  <FieldArray
    name="recipients"
    component={renderRecipents}
    findRecipients={props.findRecipients}
    autofillSelectedRecipients={props.autofillSelectedRecipients}
    removeSingleRecipient={props.removeSingleRecipient}
    recipients={props.recipientsEmails}
    isFetching={props.isFetching}
  />
);

export default connect(
  state => ({
    recipientsEmails: state.Admin.scenes.Deliveries.scenes.new.data.recipients.data.map((recipient, i) => { return { value: JSON.stringify(recipient), text: `Email: ${recipient.email},   Name: ${recipient.firstName} ${recipient.lastName},  Phone: ${recipient.cell}` } }),
    //recipientsEmails: state.Admin.scenes.Deliveries.scenes.new.data.recipients.data.map((recipient) => recipient.email),
    isFetching: state.Admin.scenes.Deliveries.scenes.new.data.recipients.isFetching,
    error: state.Admin.scenes.Deliveries.scenes.new.data.recipients.hasError
  }),
  dispatch => ({
    ...bindActionCreators(actionCreators, dispatch), dispatch
  })
)(Recipients);

