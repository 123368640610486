import React from 'react';
import Dropzone from 'react-dropzone';
import { message } from 'antd';
import 'antd/es/message/style/index.css';
import { FieldArray } from 'redux-form';
import { MdClear, MdAttachFile } from 'react-icons/md';

const MAX_FILE_SIZE_MB = 300;
const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1000 * 1000;

const allowedFileTypes = [
  '.xls',
  '.xlsx',
  '.csv',
  '.txt',
  '.doc',
  '.docx',
  '.pdf',
  '.xml',
  '.rar',
  '.zip',
  '.zipx',
  '.7z'
]

const handleFileDrop = async (files, fields) => {
  files.forEach( (file) => {
    fields.push(file);
  });
}

// TODO: Translate
const renderFileUpload = ({ fields, meta, children }) => {
  const errorMessage = (files) => {
    if (files.some(({ size }) => size > MAX_FILE_SIZE)) {
      message.error('Filstorleken är för stor')  
    } else {
      message.error('Ogiltig filtyp för filöverföringen')
    }
  }
  const hasError = meta.submitFailed && meta.error;
  return (
    <>
      <Dropzone onDrop={acceptedFiles => handleFileDrop(acceptedFiles, fields)} accept={allowedFileTypes} onDropRejected={errorMessage} maxSize={MAX_FILE_SIZE}>
      {({getRootProps, getInputProps}) => (
        <section>
          <div className='dropzone card__body' {...getRootProps()}>
            <input {...getInputProps()} />
            {children}
          </div>
        </section>
      )}
      </Dropzone>
      {hasError && <span className="help-text help-text--has-error">{meta.error}</span>}

      {fields.map((file, index) => (
        <div className="card mt1" key={index}>
          <div className="card-header">
            <MdAttachFile className="card-header__icon" />
            <span className="card-header__title">{fields.get(index).name}</span>
            <div className="card-header__actions">
              <button
                className="card__action card__action--remove"
                type="button"
                title="Remove file"
                onClick={() => fields.remove(index)}
              >
                <MdClear />
              </button>
            </div>
          </div>
        </div>
      ))}
    </>
  )
};

let FileUpload = (props) => (
  <FieldArray component={renderFileUpload} name="fileDrop" {...props} />
);

export default FileUpload;
