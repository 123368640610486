import { message } from "antd";
import Auth0 from "../../store/actions/auth/Auth";
import authActions from "../../store/actions/auth";

export const SET_USER_AUTH0_DATA = 'SET_USER_AUTH0_DATA';
export const CLEAR_USER_DATA = 'CLEAR_USER_DATA';
export const SET_USER_LOADING = 'SET_USER_LOADING';

export const actionCreators = {
  setAuth0ClientInfo: (clientInfo) => (dispatch) => {
    dispatch({
      type: SET_USER_AUTH0_DATA,
      payload: clientInfo,
    });
  },
  clearUserData: () => (dispatch) => {
    dispatch({ type: CLEAR_USER_DATA });
  },
  didSessionExpire: () => async (dispatch) => {
    try {
      const token = Auth0.getAccessToken();
      const result = Auth0.validateAccessToken(token);

      if (result === 13) {
        await message.error("Inloggningssessionen har gått ut. Försöker logga in igen...", 3);
        throw new Error('Session has been expired');
      }
    } catch (e) {
      dispatch(authActions.signoutUser());
      dispatch(actionCreators.clearUserData());
      dispatch(authActions.signinUser());
    }
  },
  refreshSession: () => async (dispatch) => {
    try {
      dispatch({ type: SET_USER_LOADING, payload: true });

      await Auth0.refreshSession();
      dispatch(actionCreators.setAuth0ClientInfo(Auth0.getClientInfo()));
    } catch (err) {
      console.error("Error refreshing session: ", err);
    } finally {
      dispatch({ type: SET_USER_LOADING, payload: false })
    }
  },
}