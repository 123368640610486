import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../actions';
import { push } from 'connected-react-router';
import { MdFileDownload } from 'react-icons/md';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';

class Files extends Component {
  componentWillMount() {
    if(!this.props.deliveryId) {
      console.warn('OrderId is null or empty in store object. Redirecting user to root...');
      this.props.dispatch(push('/'));
    } else {
      this.props.getFileList(this.props.deliveryId);
    }
  }

  downloadFile(fileId) {
    this.props.getFile(fileId);
  }

  render() {
    const { t, errorMessage, isFetching, hasError, hasFiles, files } = this.props;

    if (isFetching) {
      return(
        <div>{t('files.loading')}</div>
      )
    }

    if (hasError) {
      return(
        <div>{t('files.error')}</div>
      )
    }

    if (hasFiles) {
      return(
        <div className="download">
          <h1 className="h3">{t('files.header')}</h1>
          <p>{t('files.info')}</p>
          {files.map((file, index) => (
            <div className="download__list" key={index}>
              <span className="download__label">{file.name}</span>
              <button className="download__btn btn btn--primary" onClick={ () => this.downloadFile(file.id) }><MdFileDownload /></button>
            </div>
          ))}

          {errorMessage && <span className="help-text help-text--has-error">{errorMessage}</span>}
        </div>
      );
    }

    return null;
  }
};

export default compose(
  connect(
    state => state.deliveries,
    dispatch => ({
      ...bindActionCreators(actionCreators, dispatch), dispatch
    })
  ),
  withTranslation('deliveries')
)(Files);
