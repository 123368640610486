import React from 'react';
import NavigationList from './components/NavigationList';

const Navigation = ({navItems}) => (
  <nav className="main-navigation">
    <NavigationList navItems={navItems} />
  </nav>
);

export default Navigation;
