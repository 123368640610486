import React from 'react';
import { Link } from 'react-router-dom';
import AdminLayout from '../../components/Layout/AdminLayout';

const links = [
  {
    to: '/admin/deliveries/new',
    title: 'Skapa ny leverans'
  },
  {
    to: '/admin/uploads',
    title: 'Se inskickade kundfiler'
  }
]

const Admin = () => (
  <AdminLayout>
    <div className="page-wrap">
      <h1>Välkommen! Vad behöver du göra idag?</h1>
      {links.map((link, i) => (
        <Link
          to={link.to}
          key={i}
          data-testid="link"
          role="link"
          className="admin__link"
        >
          <button className="btn btn--primary">{link.title}</button>
        </Link>
      ))}
    </div>
  </AdminLayout>
)

export default Admin;
