import { combineReducers } from 'redux';

import { reducer as dataReducer } from './scenes/New/reducer';

export const reducer = combineReducers({
  scenes: combineReducers({
    new: dataReducer,
    show: {}
  }),
  data: {}
});
