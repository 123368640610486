import React from 'react';
import s from './UploadProgress.module.sass';
import { Progress } from 'antd';
import 'antd/es/progress/style/index.css';
import RetryUpload from './components/RetryUpload';
 
const UploadProgress = ({
  fileName = 'cool file',
  progress = 30,
  status = 'active',
  file,
  deliveryId,
  postFile,
  uploadComplete,
}) => {
  const onClick = async () => {
    try {
      await postFile(file, deliveryId)
      uploadComplete(deliveryId);
    } catch (err) {
      console.error(err, "Error uploading file")
    }
  }

  return (
    <ul className={s['file-list']}>
      <li className={s['file-list__file']}>
        {status === 'exception' &&
          <RetryUpload onClick={onClick} className={s['retry-button']} />
        }
        <div className={s['file-container']}>
          <span className={s['file-name']}>{fileName}</span>
          <Progress percent={progress} status={status} />
          {status}
        </div>
      </li>
    </ul>
  );
}
 
export default UploadProgress;
