import React from 'react';
import { Switch } from 'antd';
import 'antd/lib/switch/style/index.css';
import s from './ModeSwitch.module.sass'
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

const handleSwitchMode = (isPublic, props) => {
  if(isPublic) {
    props.dispatch(push('/admin'));
  } else {
    props.dispatch(push('/'));
  }
}

const ModeSwitch = ({ Auth0, ...rest }) => {
  const isPublic = !window.location.pathname.includes('admin') && Auth0.hasScopes(['deliveries:send-to-customer', 'deliveries:receive-from-customer']);

  if (!Auth0.hasScopes(['deliveries:send-to-customer', 'deliveries:receive-from-customer'])) {
    return <div />;
  };

  return (
    <div className={s['switch-wrapper']}>
      Publikt läge <Switch onClick={ () => handleSwitchMode(isPublic, rest) } checked={isPublic} />
    </div>
  )
};

export default connect(
  null,
  dispatch => ({
    dispatch,
    push
  })
)(ModeSwitch);
