import React from 'react';

const renderField = ({ input, label, type, name, placeholder, t, meta: { touched, error, warning } }) => {
  return (
  <div className="form-group">
    <label className="label" htmlFor={name}>{label}</label>
    <input {...input} placeholder={placeholder} type={type} id={name} className={`form-control ${touched && error ? 'form-control--has-error' : ''} ${!error && touched ? 'form-control--has-success' : ''}`} />
    {touched &&
      ((error && <span className="help-text help-text--has-error">{!error.includes(" ") ? t(`findDelivery.validateForm.${error}`) : error}</span>) ||
        (warning && <span>{warning}</span>))}
  </div>
)};

export default renderField;
