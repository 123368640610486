import React from 'react';
import { WithWizard } from 'react-albus';
import { MdCheck, MdRadioButtonUnchecked } from 'react-icons/md';

const Progress = ({ stepInfo, stepNames }) => (
  <WithWizard
    render={({ step, steps }) => {
      const stepList = steps.map((element, index) => {
        let stepClassName = "progress-meter__element";
        const markerContent = (index < steps.indexOf(step)) ? <MdCheck />  : <MdRadioButtonUnchecked /> ;

        if (index === steps.indexOf(step))
          stepClassName += ' progress-meter__element--active';
        if (index < steps.indexOf(step))
          stepClassName += ' progress-meter__element--completed';

        return (
          <li className={stepClassName} key={index}>
            <span className="progress-meter__marker">{markerContent}</span>
            <span className="progress-text">
              <h4 className="progress-title">{stepNames[index]}</h4>
            {stepInfo[index]}</span>
          </li>
        )
      })
      return (
        <div className="progress-meter-wrapper">
          <ul className="progress-meter"> 
            {stepList}
          </ul>
        </div>
      )
    }}
  />
);

export default Progress;
