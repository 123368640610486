import React from 'react';
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import s from './PrefillDrowdown.module.sass'

import 'antd/es/dropdown/style/index.css';

const PrefillDropdown = ({loadDeliveryMessage}) => {
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1" value="en">
        Engelska
      </Menu.Item>
      <Menu.Item key="2" value="sv">
        Svenska
      </Menu.Item>
    </Menu>
  );  
  function handleMenuClick(e) {
    loadDeliveryMessage(e.item.props.value);
  }
  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <a className={s.droplink} onClick={e => e.preventDefault()}>
        Byt språk
      </a>
    </Dropdown>
  )
};

export default PrefillDropdown;
  