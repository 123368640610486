import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import Auth0 from "../actions/auth/Auth";
import authActionCreators from "../actions/auth";
import { actionCreators as userActionCreators } from "../../data/user/actions";

class AuthProvider extends Component {
  componentDidMount() {
    if (this.props.authenticated) {
      this.props.didSessionExpire();
    } else {
      this.props.setAuth0ClientInfo(Auth0.getClientInfo());
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.authenticated && this.props.authenticated) {
      this.props.setAuth0ClientInfo(Auth0.getClientInfo());
    }
  }

  render() {
    const { children, email, redirectToVerify } = this.props;

    if (email && redirectToVerify) {
      return <Redirect to="/verify" />
    }

    return children;
  }
}

const mapStateToProps = (state, props) => ({
  authenticated: state.auth.authenticated,
  redirectToVerify: !state.user.clientInfo.email_verified && props.location.pathname !== "/verify",
  email: state.user.clientInfo.email,
});

const mapActionCreators = {
  ...authActionCreators,
  ...userActionCreators,
};

const withRedux = connect(mapStateToProps, mapActionCreators);

const withReduxAndRouter = (C) => withRouter(withRedux(C));

export default withReduxAndRouter(AuthProvider);
