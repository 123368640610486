import { combineReducers } from 'redux';

import { reducer as dataReducer } from './data/reducer';
import { reducer as uploadsReducer } from './scenes/Uploads/reducer';
import { reducer as deliveriesReducer } from './scenes/Deliveries/reducer';

export const reducer = combineReducers({
  data: dataReducer,
  scenes: combineReducers({
    Uploads: uploadsReducer,
    Deliveries: deliveriesReducer
  })
});
