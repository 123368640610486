import React from 'react';
import { MdSend, MdCloudUpload } from 'react-icons/md';
import GdprInput from '../GdprInput/GdprInput';
import Terms from '../Terms/';
import Recipients from '../Recipients/Recipients';
import Message from '../Message/Message';
import submitForm from '../submitForm';
// import Alert from '../../../../../../../../components/Alert/Alert';
import { Alert } from 'antd';
import 'antd/lib/alert/style/index.css';
import FileUpload from '../../../../../../../../components/FileUpload/FileUpload';
import UploadProgress from '../../../../../../../../components/UploadProgress/UploadProgress';

const Form = ({ 
  error = "Leveransen misslyckades, vänligen kontrollera samliga fält efter felmeddelanden.",
  handleSubmit,
  submitting,
  submitFailed,
  submitSucceeded,
  uploading,
  fileProgress,
  postFile,
  uploadComplete,
  loadDeliveryMessage
}) => {
  if(submitSucceeded || fileProgress.complete) {
    return null;
  } else {
    return (
      <form className="page-wrap" onSubmit={handleSubmit(submitForm)}>

        <section className="backoffice-section">
          <aside className="backoffice-section__aside">
            <div className="info-block">
              <h2 className="info-block__header">Leveransfiler</h2>
              <p className="info-block__description">Här laddar du upp de filer du vill ska ingå i leveransen.</p>
            </div>
          </aside>

          <main className="backoffice-section__main">
            <FileUpload classNames='card'>
              <MdCloudUpload className="card-header__icon" />
              <span className="card-header__title"> Släpp filer här eller klicka för att lägga till dem i listan över leveransfiler</span>
            </FileUpload>
          </main>
        </section>

        <hr />

        <section className="backoffice-section">

          <aside className="backoffice-section__aside">
            <div className="info-block">
              <h2 className="info-block__header">GDPR</h2>
              <p className="info-block__description">I fälten för GDPR fyller du i loggdata rörande personinformation.</p><p className="info-block__description">Om personinformation saknas i ordern kan du markera detta för att slippa fylla i fälten.</p><p className="info-block__description">Informationen delas ej med kund.</p>
            </div>
          </aside>

          <main className="backoffice-section__main">
            <GdprInput />
          </main>

        </section>

        <hr />

        <section className="backoffice-section">

          <aside className="backoffice-section__aside">
            <div className="info-block">
              <h2 className="info-block__header">Köpvilkor</h2>
              <p className="info-block__description">Här väljer du ett eller flera villkor som du vill automatiskt skall bifogas mailet.</p>
            </div>
          </aside>

          <main className="backoffice-section__main terms">
            <Terms />
          </main>
        </section>

        <hr />

        <section className="backoffice-section">

          <aside className="backoffice-section__aside">
            <div className="info-block">
              <h2 className="info-block__header">Mottagare</h2>
              <p className="info-block__description">Här fyller du i en eller flera mottagare av leveransen.</p>
            </div>
          </aside>

          <main className="backoffice-section__main">
          
            <Recipients />

          </main>

        </section>

        <hr />

        <section className="backoffice-section">

        <aside className="backoffice-section__aside">
          <div className="info-block">
            <h2 className="info-block__header">Meddelande</h2>
            <p className="info-block__description">Om du vill ändra leveransmeddelande kan du göra detta här, i annat fall lämnar du texten som den är.</p>
          </div>
        </aside>

        <main className="backoffice-section__main">
          <Message loadDeliveryMessage={loadDeliveryMessage} />
        </main>


        </section>

        <hr />

        <section className="backoffice-section">
          <aside className="backoffice-section__aside">
            <div className="info-block">
              <h2 className="info-block__header">Leverans</h2>
            </div>          
          </aside>
          <main className="backoffice-section__main">

          {Object.entries(fileProgress)
            .filter(([key]) => key !== 'complete')
            .map(([name, fileProps], key) => 
              <UploadProgress
                key={key}
                fileName={name}
                postFile={postFile}
                uploadComplete={uploadComplete}
                {...fileProps}
              />
          )}

          <div>
            <button type="submit" className="btn btn--block btn--primary mb1" disabled={submitting || uploading}>Skicka leverans <MdSend /></button>
          </div>

          {submitFailed &&
            <Alert
              message="Fel"
              description={error}
              type="error"
              showIcon
              closable
            />
          }
          </main>
        </section>
      </form>
    )
  }
}

export default Form;
