import React from 'react';
import NavigationItem from './NavigationItem';

const NavigationList = ({ navItems }) => (
  <nav className="main-navigation">
    <ul className="navigation">
      {navItems.map((item, index) => <NavigationItem item={item} index={index} key={index} />)}
    </ul>
  </nav>
);

export default NavigationList;
