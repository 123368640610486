import { push } from 'connected-react-router'

const onRowClick = (state, rowInfo, column, instance) => {
  return {
    onClick: e => {
        instance.state.dispatch(push(`/admin/uploads/${rowInfo.row._original.deliveryId}`))
    }
  }
}

export default onRowClick;
