import { Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import s from './LanguageSelector.module.sass'

const Option = Select.Option;

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  return (
    <Select defaultValue={i18n.language} style={{ width: 95 }} onChange={changeLanguage} className={s.selector} placeholder="Language">
      <Option value="sv">Svenska</Option>
      <Option value="en">English</Option>
    </Select>
  )
}

export { LanguageSelector };
export default LanguageSelector;
