import axios from 'axios';
import Auth0 from '../../../../store/actions/auth/Auth';

export const GET_SINGLE_UPLOAD = 'GET_SINGLE_UPLOAD';
export const GET_SINGLE_UPLOAD_PENDING = 'GET_SINGLE_UPLOAD_PENDING';
export const GET_SINGLE_UPLOAD_FULFILLED = 'GET_SINGLE_UPLOAD_FULFILLED';
export const GET_SINGLE_UPLOAD_REJECTED = 'GET_SINGLE_UPLOAD_REJECTED';
export const SET_SINGLE_UPLOAD_DATA = 'SET_SINGLE_UPLOAD_DATA';
export const CLEAR_SINGLE_UPLOAD_DATA = 'CLEAR_SINGLE_UPLOAD_DATA';

export const actionCreators = {
  getSingleUpload: (deliveryId) => async (dispatch) => {
    dispatch({
      type: GET_SINGLE_UPLOAD,
      payload: axios.get(`/api/admin/deliveries/fromCustomer/${deliveryId}`, {
        headers: { "Authorization": `Bearer ${Auth0.getAccessToken()}` }
      })
    })
  },
  setSingleUploadData: (data) => async (dispatch) => {
    dispatch({
      type: SET_SINGLE_UPLOAD_DATA,
      payload: data
    })
  },
  clearSingleUploadData: () => async (dispatch) => {
    dispatch({
      type: CLEAR_SINGLE_UPLOAD_DATA,
    })
  }
};
