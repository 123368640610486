/* eslint-disable no-mixed-operators */
import React from 'react';
import { MdCheckCircle } from 'react-icons/md'
import 'rc-collapse/assets/index.css';
import Collapse, { Panel } from 'rc-collapse';
import CustomerGdprData from './components/CustomerGdprData/';
import CustomerGdprForm from './components/CustomerGdprForm/';
import MiseGdprData from './components/MiseGdprData/';
import MiseGdprForm from './components/MiseGdprForm/';
import s from './Gprd.module.sass'

const Gdpr = ({ uploadData: { gdpr, status, selectedSuppliers, id }, uploadData, deleteSingleUpload, initialValues }) => (
  <div className={s['gdpr-section']}>
    <h1 className={s['gdpr-section__header']}>GDPR</h1>
    { gdpr.hasPersonalDetails ? (
      <Collapse accordion={true} defaultActiveKey="1">
        <Panel header={<span>GDPR-info från kund</span>} extra={status === 'Approved' || status === 'Complete' && <MdCheckCircle className={`${s.icon} ${s['icon--approved']}`} />} key="1">
          {
            status === 'New' &&
            <CustomerGdprForm s={s} uploadData={uploadData} initialValues={initialValues} deleteSingleUpload={deleteSingleUpload} deliveryId={id} />
          }
          {
          status !== 'New' &&
            <CustomerGdprData gdprData={gdpr} s={s} />
          }
        </Panel>
        <Panel header={<span>GDPR-info från MISE</span>} extra={status === 'Complete' && <MdCheckCircle className={`${s.icon} ${s['icon--approved']}`} />} key="2" className="suppliers-panel"><MiseGdprData suppliers={selectedSuppliers} />
          {
            status === 'Approved' &&
            <MiseGdprForm id={id} s={s}/>
          }
          {
            status !== 'Complete' &&
            <MiseGdprData suppliers={selectedSuppliers} />
          }
        </Panel>
      </Collapse>
    ) : (
      <span>Ingen GDPR-data behöver granskas för denna sändning.</span>
    )}
  </div>
)

export default Gdpr;
