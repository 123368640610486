export const SET_DELIVERY_MESSAGE = 'SET_DELIVERY_MESSAGE';

export const DELIVERY_MESSAGE = {
  sv: 'Hej! \n\nKlicka på knappen nedan för att hämta din leverans.\n\nMed vänliga hälsningar Leverans och Support',
  en: 'Hi, \n\nPlease click the button below to fetch your delivery.\n\nKind regards, Delivery and support department'
};

export const actionCreators = {
  loadDeliveryMessage: data => ({
    type: SET_DELIVERY_MESSAGE,
    data: DELIVERY_MESSAGE[data]
  })
};
