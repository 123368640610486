import {
  SET_DELIVERY_ID,
  FETCH_FILE_LIST_PENDING,
  FETCH_FILE_LIST_FULFILLED,
  FETCH_FILE_LIST_REJECTED,
  FETCH_USER_PERMISSIONS_PENDING,
  FETCH_USER_PERMISSIONS_FULFILLED,
  FETCH_USER_PERMISSIONS_REJECTED,
  FETCH_FILE_FULFILLED,
  FETCH_FILE_REJECTED_MANUAL,
} from './actions'

const initialState = {
  deliveryId: '',
  hasFiles: false,
  userCanUploadFiles: true,
  errorMessage: '',
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch(action.type) {
    case SET_DELIVERY_ID:
      return {
        ...state,
        deliveryId: action.value
      };
    case FETCH_FILE_LIST_PENDING:
    case FETCH_USER_PERMISSIONS_PENDING:
      return {
        ...state,
        isFetching: true,
      }
    case FETCH_FILE_LIST_FULFILLED:
      return {
        ...state,
        files: [...action.payload.data.files],
        isFetching: false,
        hasFiles: true,
        errorMessage: ''
      };
    case FETCH_FILE_LIST_REJECTED:
    case FETCH_USER_PERMISSIONS_REJECTED:
      return {
        ...state,
        isFetching: false,
        hasError: true
      }
    case FETCH_USER_PERMISSIONS_FULFILLED:
      return {
        ...state,
        userCanUploadFiles: action.payload.data.userCanUploadFiles,
        isFetching: false,
      }
    case FETCH_FILE_FULFILLED:
      return {
        ...state,
        isFetching: false,
        errorMessage: ''
      }
    case FETCH_FILE_REJECTED_MANUAL:
      return {
        ...state,
        isFetching: false,  
        errorMessage: action.payload,
      }
    default:
      return state;
  }
};
