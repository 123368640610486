import React from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Wizard, Steps, Step } from 'react-albus';
import UploadType from './scenes/UploadType';
import Progress from './components/Progress';
import Files from './scenes/Files';
import Recipient from './scenes/Recipient';
import MainLayout from '../../../../components/Layout/MainLayout';
import s from './Upload.module.sass';
import { MdLock } from 'react-icons/md';
import SecurityModal from './components/SecurityModal';
import { useTranslation } from 'react-i18next';

const CustomerUpload = ({ history, userCanUploadFiles }) => {
  const { t } = useTranslation('upload');
  const stepNames = [
    {
      title: t('stepNames1')
    },
    {
      title: t('stepNames2')
    },
    {
      title: t('stepNames3')
    }
  ];
  return (
  <MainLayout title={t('page-title')} userCanUploadFiles={userCanUploadFiles}>
    <div className={s['customer-upload'] + ' card'}>
      <Wizard
        history={history}
        render={({ step, next, previous }) => (
          <div>
            <div className="wizard">
              <Progress stepNames={stepNames} />
              <Steps key={step.id} step={step.id ? step : undefined}>
                <Step id="upload/type">
                  <UploadType onSubmit={next} id="uploadType" t={t} />
                </Step>
                <Step id="upload/files" className="step">
                  <Files onSubmit={next} previous={previous} id="files" t={t} />
                </Step>
                <Step id="upload/recipient" className="step">
                  <Recipient previous={previous} id="recipient" t={t} />
                </Step>
              </Steps>
            </div>
          </div>
        )}
      />
    </div>
    <div className={s['security-message']}>
      <MdLock className={s['security-message__icon']} />
      <div className={s['security-message__text']}>{t('securityModal.openModalMessagePartOne')}<SecurityModal s={s} />{t('securityModal.openModalMessagePartTwo')}</div>
    </div>
  </MainLayout>
  );
}

const mapStateToProps = state => ({
  userCanUploadFiles: state.deliveries.userCanUploadFiles,
})

export default compose(
  withRouter,
  connect(mapStateToProps, null)
)(CustomerUpload);
