import React from 'react';

const horizontalFormInput = ({
  input,
  className,
  id,
  label,
  type,
  placeholder,
  renderAs,
  rows,
  maxlength,
  helpText,
  disabled,
  meta: { touched, error, warning }
}) => (
  <div className='form-group form-group--horizontal'>
    <div>
      <label htmlFor={id} className="label">{label}</label>
    </div>
    <div>
      {renderAs === 'textarea' ? (
        <textarea {...input} placeholder={placeholder} className={`${className} ${touched && error ? 'form-control--has-error' : ''} ${warning && touched ? 'form-control--has-warning' : ''} ${!error && !warning && touched ? 'form-control--has-success' : ''}`} id={id} name={id} rows={rows} maxLength={maxlength} />
      ) : (
        <input {...input} placeholder={placeholder} type={type} className={`${className} ${touched && error ? 'form-control--has-error' : ''} ${warning && touched ? 'form-control--has-warning' : ''} ${!error && !warning && touched ? 'form-control--has-success' : ''}`} id={id} name={id} disabled={disabled} />
      )}

      {touched && error ? (
        <span className='help-text help-text--has-error'>{error}</span>
      ) : helpText && (
        <span className="help-text">{helpText}</span>
      )}
    </div>
  </div>
);

export default horizontalFormInput;
