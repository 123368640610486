import React from 'react';
import { Field, reduxForm, FormSection } from 'redux-form'
import horizontalFormInput from '../../../../../../../../../../components/FormHelpers/horizontalFormInput';
import required from './helpers/required';
import submitForm from './helpers/submitForm';

const handleDeleteClick = (e, deleteSingleUpload, deliveryId) => {
  e.stopPropagation();
  if (window.confirm("Vill du verkligen radera denna leverans?")) {
    deleteSingleUpload(deliveryId);
  }
}

const CustomerGdprForm = ({ handleSubmit, deleteSingleUpload, deliveryId, s }) => (
  <div>
    <form onSubmit={handleSubmit(submitForm)}>
      <FormSection name="gdpr" className={s["form-section"]}>
        <Field component={horizontalFormInput} validate={required} className="form-control" name="origin" label="Ursprung" placeholder="Ex: Eget kundregister" helpText="Beskriv vart fil(erna) kommer från" disabled />
        <Field component={horizontalFormInput} validate={required} className="form-control" name="purpose" label="Syfte" placeholder="Ex: Ska ringa kunder och behöver telefonnummer" helpText="Beskriv vad ni ska göra med den slutgiltiga leveransen" />
        <Field component={horizontalFormInput} validate={required} className="form-control" name="noOfEntities" label="Antal poster" placeholder="Ex: ca 4500st" helpText="Beskriv ungefär hur många poster fil(erna) ni skickar innehåller" />
        <Field component={horizontalFormInput} validate={required} className="form-control" name="type" label="Typ av uppgift" placeholder="Ex: personnummer och namn" helpText="Beskriv vad för typ av personuppgifter filen innehåller." />
        <Field component={horizontalFormInput} validate={required} renderAs="textarea" className="form-control textarea--no-resize" name="description" rows="4" label="Uppdragsbeskrivning" placeholder="Vad vill du att vi ska göra med filerna?" helpText="Beskriv vad ni önskar att vi ska göra med fil(erna) ni skickar in" />
      </FormSection>
      <div className={s["collapse__footer"]}>
        <button type="submit" className="btn btn--primary">Spara beskrivning</button>
        <button type="button" className="btn btn--danger" onClick={ (e) => handleDeleteClick(e, deleteSingleUpload, deliveryId) }>Förkasta hela sändningen</button> { }
      </div>
    </form>
  </div>
)

export default reduxForm({
  form: 'CustomerGdprForm', // a unique identifier for this form
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true
})(CustomerGdprForm)
