import React from 'react';

const SupplierItem = ({supplier}) => (
  <div className="card">
    <div className="card card__body">
    <h4>{supplier.label}</h4>
    <p>{supplier.description}</p>
    </div>
  </div>
)

export default SupplierItem;
