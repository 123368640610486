import React from 'react';
import s from '../List.module.scss';
import { push } from 'connected-react-router';
import { connect } from 'react-redux'

const handleDeleteClick = (e, deleteUpload, deliveryId) => {
  e.stopPropagation();
  if (window.confirm("Vill du verkligen radera denna leverans?")) {
    deleteUpload(deliveryId);
  }
}

const handleDeliveryClick = (e, dispatch, deliveryId) => {
  e.stopPropagation();
  dispatch(push(`/admin/deliveries/new?id=${deliveryId}`));
}

const Tasks = (props) => (
  <div className={s.tasks}>
    <span onClick={ (e) => handleDeliveryClick(e, props.dispatch, props.original.deliveryId) } className={s['tasks__action']}>Leverera</span>
    { } | { }
    <span onClick={ (e) => handleDeleteClick(e, props.deleteUpload, props.original.deliveryId) } className={s['tasks__action']}>Radera</span> 
  </div>
);

export default connect(
  null,
  dispatch => ({
    dispatch,
    push
  })
)(Tasks);
