import React from 'react';
import { Field, reduxForm, FormSection } from 'redux-form'
import normalizeBoolean from '../../../../../../components/FormHelpers/normalizeBoolean';
import s from './UploadType.module.sass';
import { useTranslation } from 'react-i18next';
import validate from './components/validate';

const renderField = ({
  input,
  label,
  type,
  t,
  meta: { touched, error, warning }
}) => (
  <div>
    <label>
      <input {...input} placeholder={label} type={type} /> { '' }
      {label}
    </label>
    {touched &&
      ((error && <span className="help-text help-text--has-error">{t(`uploadType.validate.${error}`)}</span>) ||
        (warning && <span>{warning}</span>))}
  </div>
)

const UploadType = ({handleSubmit}) => {
  const { t } = useTranslation('upload');
  return (
  <form onSubmit={handleSubmit}>
    <h4>{t('uploadType.header')}</h4>
    <label>{t('uploadType.label')}</label>
    <FormSection className={s['upload-form']} name="gdpr">
      <Field
        name="hasPersonalDetails"
        component={renderField}
        type="radio"
        value={false}
        label={t('uploadType.field.label1')}
        normalize={normalizeBoolean}
        validate={validate}
        t={t}
      />
      <Field
        name="hasPersonalDetails"
        component={renderField}
        type="radio"
        value={true}
        normalize={normalizeBoolean}
        label={t('uploadType.field.label2')}
        validate={validate}
        t={t}
      />
    </FormSection>
    <button type="submit" className="btn btn--primary">
      {t('uploadType.button')}
    </button>
  </form>
  );
}

export default reduxForm({
  form: 'customer-upload',
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(UploadType)
