import React from 'react';
import { connect } from 'react-redux'
import { Field, FormSection, formValueSelector } from 'redux-form';
import horizontalFormInput from '../../../../../../../../components/FormHelpers/horizontalFormInput';
// import RadioGroupInput from '../FormHelpers/radioGroupInput'; //TODO: Do we need any of this for the validation? Otherwise, just remove the component
import Suppliers from '../../../../../../components/Suppliers';
import normalizeBoolean from '../../../../../../../../components/FormHelpers/normalizeBoolean';
import required from '../../../../../../../../components/FormHelpers/required';

const needsToBePicked = value => (value || typeof value === 'boolean' ? undefined : 'Fältet måste fyllas i')

const renderField = ({
  input,
  label,
  type,
  meta: { touched, error, warning }
}) => (
  <div className="form-check form-check--inline">
    <label className="form-check__label">
      <input {...input} placeholder={label} type={type} className="form-check__input" />
      {label}
    </label>
      {touched &&
        ((error && <span className="help-text help-text--has-error">{error}</span>) ||
          (warning && <span>{warning}</span>))}
  </div>
)

const GdprInput = ({ hasPersonalDetails }) => (
  <>
    <FormSection name="gdpr">
      <Field
        name="hasPersonalDetails"
        component={renderField}
        type="radio"
        value={true}
        normalize={normalizeBoolean}
        label="Register innehållande personuppgifter"
        validate={needsToBePicked}
      />
      <Field
        name="hasPersonalDetails"
        component={renderField}
        type="radio"
        value={false}
        normalize={normalizeBoolean}
        label="Register utan personuppgifter"
        validate={needsToBePicked}
      />
      {hasPersonalDetails && (
        <>
        <div className="card">
          <div className="card-header">
            <div className="card-header__title">Information om filinnehåll</div>
          </div>

          <div className="card__body form">
            <Field component={horizontalFormInput} validate={required} className="form-control" name="type" label="Typ av uppgift" placeholder="Ex: namn och e-postadresser/adressuppgifter till privatpersoner" />
            <Field component={horizontalFormInput} validate={required} className="form-control" name="purpose" label="Syfte" placeholder="Ex: kundens DR aktivitet" />
            <Field component={horizontalFormInput} validate={required} className="form-control" name="noOfEntities" label="Antal poster" placeholder="Ex: ca 4500st" />
            <Field component={horizontalFormInput} validate={required} renderAs="textarea" className="form-control textarea--no-resize" name="description" rows="4" label="Uppdragsbeskrivning" placeholder="Urvalsparametrar, dvs vad har kunden beställt" />
          </div>

        </div>
        </>
      )}
    </FormSection>
    {hasPersonalDetails && (
      <>
        <h2>Adresskällor</h2>
        <Suppliers />
      </>
    )}
  </>
);

const selector = formValueSelector('miseUpload');

export default connect(state => {
  const hasPersonalDetails = selector(state, 'gdpr.hasPersonalDetails')
  return {
    hasPersonalDetails
  }
})(GdprInput);
