import React from 'react';
import PageHeader from './PageHeader/PageHeader';
import { customerMenu } from './helpers/navigationData';

const DeliveryLayout = ({ title, children, userCanUploadFiles }) => (
  <>
    <PageHeader title={title} navItems={customerMenu} userCanUploadFiles={userCanUploadFiles} />
    <main className="center">
      <div className="box-container">
        <div>
          {children}
        </div>
      </div>
    </main>
  </>
);

export default DeliveryLayout;
