import React, { Component } from 'react';
import { Menu, Drawer } from 'antd';
import 'antd/lib/drawer/style/index.css';
import 'antd/lib/menu/style/index.css';
import { MdMenu, MdClose } from 'react-icons/md'
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withTranslation } from 'react-i18next';
import s from './SideMenu.module.sass'

class SideMenu extends Component {
  state = {
    visible: false,
  }
  onSwitch = () => {
    this.setState({
      visible: !this.state.visible,
    });
  }
  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  handleMenuItemClick = (link) => {
    this.props.dispatch(push(link));
  }
  //TODO: Translate menu title
  render() {
    const { t } = this.props;
    const { navItems } = this.props;
    return (
      <>
        <Drawer visible={this.state.visible} placement="left" title="Meny" onClose={this.onClose} width={256}>
          <Menu
            style={{ width: 256 }}
            mode="inline"
            onClick={this.onClick}
          >
            {navItems.map((item, index) =>
            <Menu.Item key={index} onClick={() => this.handleMenuItemClick(item.link) }>{t(item.label)}</Menu.Item>
            )}
          </Menu>
        </Drawer>
        <button
        onClick={this.onSwitch}
        className={s.menu}
        >{!this.state.visible ? <MdMenu /> : <MdClose />}</button>
      </>
    )
  }
}

export default compose(
  connect(
    null,
    dispatch => ({
      dispatch,
      push
    })
  ),
  withTranslation('pageHeader')
)(SideMenu);
