const validate = values => {
  const errors = {};

  if (!values.fileDrop || !values.fileDrop.length) {
    errors.fileDrop = { _error: 'Minst en leveransfil måste bifogas' }
  } 

  // Recipients
  if (!values.recipients || !values.recipients.length) {
    errors.recipients = { _error: 'Minst en mottagare av leveransen måste existera' }
  } else {
    const recipientsArrayErrors = []

    values.recipients.forEach((recipient, recipientIndex) => {
      const recipientErrors = {}

      if (!recipient || !recipient.firstName) {
        recipientErrors.firstName = 'Förnamn måste anges'
        recipientsArrayErrors[recipientIndex] = recipientErrors
      }

      if (!recipient || !recipient.lastName) {
        recipientErrors.lastName = 'Efternamn måste anges'
        recipientsArrayErrors[recipientIndex] = recipientErrors
      }

      if (!recipient || !recipient.email) {
        recipientErrors.email = 'E-postadress måste anges'
        recipientsArrayErrors[recipientIndex] = recipientErrors
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(recipient.email)) {
        recipientErrors.email = 'Ogiltigt format på e-postadress'
        recipientsArrayErrors[recipientIndex] = recipientErrors
      }

      if (!recipient || !recipient.cell) {
        recipientErrors.cell = 'Mobilnummer måste anges för leveranser'
        recipientsArrayErrors[recipientIndex] = recipientErrors
      }

      if (!recipient || !recipient.companyName) {
        recipientErrors.companyName = 'Företagsnamn måste anges'
        recipientsArrayErrors[recipientIndex] = recipientErrors
      }

      if (recipient.organizationNo && !/^[0-9\d]{6}[-—\D]{1}[0-9\d]{4}|[0-9\d]{10}$/i.test(recipient.organizationNo)) {
        recipientErrors.organizationNo = 'Ogiltigt format på Organisationsnummer'
        recipientsArrayErrors[recipientIndex] = recipientErrors
      }

    })

    if (!values || !values.deliveryMessage) {
      errors.deliveryMessage = 'Ett leveransmeddelande måste anges';
    }

    if (recipientsArrayErrors.length) {
      errors.recipients = recipientsArrayErrors
    }

  }

  // Suppliers
  if (values.selectedSuppliers) {
    const selectedSuppliersArrayErrors = []
  
    values.selectedSuppliers.forEach((supplier, supplierIndex) => {
      const supplierErrors = {}
  
      if (!supplier || !supplier.description) {
        supplierErrors.description = 'En beskrivning måste anges'
        selectedSuppliersArrayErrors[supplierIndex] = supplierErrors
      }
  
    })
  
    if (selectedSuppliersArrayErrors.length) {
      errors.selectedSuppliers = selectedSuppliersArrayErrors
    }
  
  }

  for(let prop in errors) {
    if (errors.hasOwnProperty(prop)) {
      errors._error = 'Leveransen misslyckades, vänligen kontrollera samliga fält efter felmeddelanden.'
    }
  }

  return errors
}

const warn = values => {
  const warnings = {};
  const recipientsArrayWarnings = []

  values.recipients.forEach((recipient, recipientIndex) => {
    const recipientWarnings = {};
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,3}$/i.test(recipient.email)) {
      recipientWarnings.email = 'E-postadressen ser ovanlig ut. Kontrollera den en extra gång innan du skickar!'
      recipientsArrayWarnings[recipientIndex] = recipientWarnings
    }
  })

  if (recipientsArrayWarnings.length) {
    warnings.recipients = recipientsArrayWarnings
  }

  return warnings
}

export { validate, warn };
