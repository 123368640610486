import React from 'react';
import ReactTable from "react-table";
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from './data/uploads/actions';
import getStatusMarkup from './components/getStatusMarkup';
import Badge from '../../../../../../components/Badge';
import onRowClick from './components/onRowClick';
import "react-table/react-table.css";
import s from './List.module.scss';
import Tasks from './components/Tasks';

// TODO: Fix resize of columns, fix mobile responsive view.
// TODO: Fix Pagination bug!!
const List = ({ dispatch, hasError, data, pages, isFetching, getFileList, deleteUpload }) => {
  if(hasError) {
    return <div>Fel vid hämtning av data</div>
  }
  return (
    <div className="page-wrap">
      <div className="card">
        <ReactTable
          data={data}
          columns={[
            {
              Header: "Filnamn",
              accessor: "fileNames",
              Cell: props => (<>{props.row.fileNames[0]} { props.row.fileNames.length > 1 && <Badge type="primary">+{props.row.fileNames.length - 1}</Badge>}</>)
            },
            {
              Header: "Inskickad av",
              accessor: "sentBy"
            },
            {
              Header: "Mottagare",
              accessor: "recipient"
            },
            {
              Header: "Ankomstdatum",
              accessor: "arrivalDate",
              Cell: props => (<Moment format="YYYY-MM-DD">{props.row.arrivalDate}</Moment>)
            },
            {
              Header: "Raderas",
              accessor: "deletionDate",
              Cell: props => (<Moment format="YYYY-MM-DD">{props.row.deletionDate}</Moment>)
            },
            {
              Header: "Status",
              accessor: "status",
              Cell: props => getStatusMarkup(props.row.status)
            },
            {
              Header: "Åtgärder",
              accessor: "tasks",
              Cell: cell => <Tasks deleteUpload={deleteUpload} {...cell} />
            }
          ]}
          // General
          className="-striped -highlight"
          loading={isFetching} // Display the loading overlay when we need it
          onFetchData={getFileList} // Request new data when things change
          pages={pages} // Display the total number of pages
          manual // Ensure all handling of data is server-side
          sortable={false}
          multiSort={false}
          resizable={false}
          // Text
          previousText="Föregående"
          nextText="Nästa"
          loadingText="Laddar..."
          noDataText="Ingen data att visa"
          pageText="Sida"
          ofText="av"
          rowsText="rader"
          getTrProps={onRowClick}
          dispatch={dispatch} // Passing in push from connected-react-router
        />
      </div>
    </div>
  )
};

export default connect(
  state => {
    const { data, pages, isFetching, hasError } = state.Admin.scenes.Uploads.scenes.list.data.uploads;
    return {
      data: data,
      pages: pages,
      isFetching: isFetching,
      hasError: hasError
    }
  },
  dispatch => ({
    ...bindActionCreators(actionCreators, dispatch), dispatch
  })
)(List);
