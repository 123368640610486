const validateForm = ( values ) => {
  const errors = {};

  if (!values.deliveryId) {
    errors.deliveryId = 'error1';
  } else if (values.deliveryId.length > 11) {
    errors.deliveryId = 'error2';
  } else if (!/^(?:d|o)\d+$/i.test(values.deliveryId)) {
    errors.deliveryId = 'error3';
  }

  if (!values.mobileNo) {
    errors.mobileNo = 'mobileNo';
  }

  return errors
}

export default validateForm;
