import React from 'react';
import { Field } from 'redux-form';
import horizontalFormInput from '../../../../../components/FormHelpers/horizontalFormInput';

const renderSelectedSuppliers= (fields) => {
  if (fields.options.length >= 1) {
    return (
      <div className="card">
        <div className="card__body">
          {fields.options.map(({ label }, i) => (
            <div className="supplier" key={i}>
              <Field
                name={`selectedSuppliers[${i}].description`}
                label={`Bearbetning från ${label}`}
                component={horizontalFormInput}
                placeholder={`Ange vad ${label} har gjort för typ av databehandling med involverade filer.`}
                renderAs="textarea"
                className="form-control textarea--no-resize"
                rows="2"
              />
            </div>
            )
          )}
        </div>
      </div>
    )
  } else {
    return null;
  }
}

export default renderSelectedSuppliers;
