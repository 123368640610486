import React from 'react';
import { Field, reduxForm, formValueSelector, FormSection } from 'redux-form'
import { connect } from 'react-redux'
import FileUpload from '../../../../../../components/FileUpload/FileUpload';
import { MdCloudUpload } from 'react-icons/md';
import validate from './components/validate';
import horizontalFormInput from '../../../../../../components/FormHelpers/horizontalFormInput';
import { compose } from 'recompose';
import s from './Files.module.sass';
import { useTranslation } from 'react-i18next';

const required = ( value, allValues, props ) => {
  const { t } = props;
  return (value || typeof value === 'number' ? undefined : t('files.required'))
}

const BaseComponent = ({handleSubmit, previous, hasPersonalDetails}) => {
  const { t } = useTranslation('upload');
  return (
  <form onSubmit={handleSubmit}>
    <h4>{t('files.header')}</h4>
    <p>{t('files.descr1')}</p>
    <FileUpload>
      <MdCloudUpload className="card-header__icon" />
      <span className="card-header__title"> {t('files.descr2')}</span>
    </FileUpload>
    {hasPersonalDetails &&
      <>
        <p>{t('files.descr3')}</p>
        <hr />
        <FormSection name="gdpr">
          <Field component={horizontalFormInput} validate={required} className="form-control" name="origin" label={t('files.gdprFields.label1')} placeholder={t('files.gdprFields.placeHolder1')} helpText={t('files.gdprFields.helpText1')} />
          <Field component={horizontalFormInput} validate={required} className="form-control" name="purpose" label={t('files.gdprFields.label2')} placeholder={t('files.gdprFields.placeHolder2')} helpText={t('files.gdprFields.helpText2')} />
          <Field component={horizontalFormInput} validate={required} className="form-control" name="noOfEntities" label={t('files.gdprFields.label3')} placeholder={t('files.gdprFields.placeHolder3')} helpText={t('files.gdprFields.helpText3')} />
          <Field component={horizontalFormInput} validate={required} className="form-control" name="type" label={t('files.gdprFields.label4')} placeholder={t('files.gdprFields.placeHolder4')} helpText={t('files.gdprFields.helpText4')} />
          <Field component={horizontalFormInput} validate={required} renderAs="textarea" className="form-control textarea--no-resize" name="description" rows="4" label={t('files.gdprFields.label5')} placeholder={t('files.gdprFields.placeHolder5')} helpText={t('files.gdprFields.helpText5')} />
        </FormSection>
      </>
    }
    <div className={s['files-footer']}>
      <button className="btn btn--transparent" type="button" onClick={previous}>{t('files.returnButton')}</button>
      <button type="submit" className="btn btn--primary">
        {t('files.proceedButton')}
      </button>
    </div>
  </form>
  );
}

const selector = formValueSelector('customer-upload');

const enhance = compose(
  connect(state => {
    const hasPersonalDetails = selector(state, 'gdpr.hasPersonalDetails')
    return {
      hasPersonalDetails
    }
  }),
  reduxForm({
    form: 'customer-upload',
    validate: validate,
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true // <------ unregister fields on unmount
  })  
)

const EnhancedComponent = enhance(BaseComponent)

export default EnhancedComponent;
