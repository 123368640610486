import React from 'react';
import FileItem from './FileItem';
import s from './FileList.module.sass';

const FileList = ({files, getFile}) => (
  <ul className={`card ${s['file-list']}`}>
    {files.map((file, index) => <FileItem file={file} key={index} getFile={getFile} />)}
  </ul>
);

export default FileList;
