import React from 'react';
import { WithWizard } from 'react-albus';
import { MdCheck } from 'react-icons/md';

const Progress = ({stepNames}) => (
  <WithWizard
    render={({step, steps }) => {
      const stepList = steps.map((element, index) => {
        let stepClassName = "progress-step";
        const markerContent = (index < steps.indexOf(step)) ? <MdCheck />  : index + 1;

        if (index === steps.indexOf(step))
          stepClassName += ' is-active';
        if (index < steps.indexOf(step))
          stepClassName += ' is-complete';

        return (
          <li className={stepClassName} key={index}>
            <span className="progress-marker">{markerContent}</span>
            <span className="progress-text">
              <h4 className="progress-title">{stepNames[index].title}</h4>
              {stepNames[index].description}
            </span>
          </li>
        )
      })
      return (
        <ul className="progress-tracker progress-tracker--text progress-tracker--spaced progress-tracker--center">
          {stepList}
        </ul>
      )
    }}
  />
);

export default Progress;
