import React from 'react';
import { Wizard, Steps, Step } from 'react-albus';
import FindDelivery from './scenes/FindDelivery';
import Authenticate from './scenes/Authenticate';
import Files from './scenes/Files';
import ProgressMeter from './components/Progress';
import DeliveriesLayout from '../../../../components/Layout/DeliveriesLayout';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

const Deliveries = ({ history, userCanUploadFiles }) => {
  const { t } = useTranslation('deliveries');
  const stepNames = [t('stepNames1'),t('stepNames2'), t('stepNames3')];
  const stepInfo = [t('stepInfo1'),t('stepInfo2'),t('stepInfo3')];

  return (
  <DeliveriesLayout title={t('page-title')} userCanUploadFiles={userCanUploadFiles}>
    <Wizard history={history} render={({ step, next }) => (
      <div className="box">
        <ProgressMeter stepInfo={stepInfo} stepNames={stepNames} />
        <Steps key={step.id} step={step.id ? step : undefined}>
          <Step id="delivery/find" path="test">
            <FindDelivery next={next} t={t} />
          </Step>
          <Step id="delivery/authenticate" className="step">
            <Authenticate next={next} />
          </Step>
          <Step id="delivery/files" className="step">
            <Files />
          </Step>
        </Steps>
      </div>
    )} />
  </DeliveriesLayout>
  )
};

const mapStateToProps = (state) => ({
  userCanUploadFiles: state.deliveries.userCanUploadFiles,
});

export default connect(mapStateToProps, null)(Deliveries);
