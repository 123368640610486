import React from 'react';
import Moment from 'react-moment';
import s from './Sidebar.module.sass'

const Sender = ({ name, email }) => {
  if (email) {
    return <a href={`mailto:${email}`}>{name}</a>
  } else {
    return <span>{name}</span>
  }
}

const Sidebar = ({ recipient = {}, sender, dateRecived }) => (
  <div className={s.sidebar}>
    <h3>Information</h3>
    <p className={s["info-section"]}>
      <strong className={s["info-header"]}>INSKICKAD AV:</strong>
      <Sender name={`${sender.firstName} ${sender.lastName}`} email={sender.email} />
    </p>
    <p className={s["info-section"]}>
      <strong className={s["info-header"]}>FÖRETAG:</strong>
      <span>{sender.companyName}</span><br />
      <small>{sender.organizationNo}</small><br />
    </p>
    <p className={s["info-section"]}>
      <strong className={s["info-header"]}>DATUM:</strong> <Moment format="YYYY-MM-DD">{dateRecived}</Moment><br /> 
    </p>
    <p className={s["info-section"]}>
      <strong className={s["info-header"]}>MOTTAGARE:</strong> {recipient.firstName} {recipient.lastName}
    </p>
  </div>
)

export default Sidebar;
