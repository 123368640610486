import { goBack } from 'connected-react-router';
import React, { Component } from 'react';
import { MdArrowBack } from 'react-icons/md';
import { connect } from "react-redux";
import MediaQuery from 'react-responsive';
import { bindActionCreators } from 'redux';
import actions from '../../../store/actions/auth';
import Auth0 from '../../../store/actions/auth/Auth';
import AccountDropdown from './components/AccountDropdown/';
import Navigation from './components/Navigation/';
import { compose } from 'recompose';
import SideMenu from './components/SideMenu';
import ModeSwitch from './components/ModeSwitch';
import { withTranslation } from 'react-i18next';
import LanguageSelector from '../../LanguageSelector';

class PageHeader extends Component {
  render() {
    const { signinUser, signoutUser, signupUser, clientInfo, title, goBack, dispatch, backLink, authenticated, userCanUploadFiles, t } = this.props;
    let { navItems } = this.props;

    if (navItems !== undefined && !userCanUploadFiles) {
      navItems = navItems.filter(({ link }) => link !== '/upload');
    }

    return (
      <header className="page-header">
        <MediaQuery maxWidth={768}>
          {navItems &&
            <SideMenu navItems={navItems} />
          }
        </MediaQuery>
        {backLink &&
        <button onClick={ () => dispatch(goBack()) } className="back-button"><MdArrowBack /></button>
        }
        <MediaQuery minWidth={769}>
          <span className="page-header__title">{title}</span>
          {navItems &&
            <Navigation navItems={navItems} />
          }
        </MediaQuery>
        <div className="page-header__actions">
          {!authenticated ? (
            <>
            <LanguageSelector />
            <button onClick={signinUser} className="btn btn--transparent btn--white">{t('login')}</button>
            <button onClick={signupUser} className="btn btn--primary">{t('createAccount')}</button>
            </>
          ) : (
            <>
              <ModeSwitch Auth0={Auth0} />
              <LanguageSelector />
              <AccountDropdown signoutUser={signoutUser} clientInfo={clientInfo} />
            </>
          )}
        </div>
      </header>
    )
  }
};

function mapStateToProps(state) {
  const { authenticated } = state.auth;
  const { clientInfo } = state.user;
  return {
    authenticated,
    clientInfo
  };
};

export default compose(
    connect(
    mapStateToProps,
    dispatch => ({
      ...bindActionCreators(actions, dispatch), dispatch,
      goBack
    })
  ),
  withTranslation('pageHeader')
)(PageHeader);
