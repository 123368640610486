export const SET_UPLOAD_DATA = 'SET_UPLOAD_DATA';
export const SET_UPLOAD_PROGRESS = 'SET_UPLOAD_PROGRESS';
export const SET_UPLOAD_STATUS = 'SET_UPLOAD_STATUS';
export const SET_UPLOAD_COMPLETE = 'SET_UPLOAD_COMPLETE';
export const CLEAR_UPLOAD_PROGRESS = 'CLEAR_UPLOAD_PROGRESS';

export const actionCreators = {
  setUploadData: ({ name, file, deliveryId }) => async (dispatch) => {
    dispatch({
      type: SET_UPLOAD_DATA,
      payload: { name, file, deliveryId },
    })
  },
  setUploadProgress: ({ name, progress }) => async (dispatch) => {
    dispatch({
      type: SET_UPLOAD_PROGRESS,
      payload: { name, progress },
    })
  },
  setUploadStatus: ({ name, status }) => async (dispatch) => {
    dispatch({
      type: SET_UPLOAD_STATUS,
      payload: { name, status },
    })
  },
  clearUploadProgress: () => ({ type: CLEAR_UPLOAD_PROGRESS }),
  setUploadComplete: complete => ({ 
    type: SET_UPLOAD_COMPLETE,
    payload: { complete }
  })
};
