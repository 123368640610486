import React from 'react';
import { MdClear } from 'react-icons/md';

// TODO: Fix dismiss and then rethrowing error. Current state: NOT working
class Alert extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: props.isVisible || false
    }
  }

  toggle() {
    this.setState({ isVisible: !this.state.isVisible });
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.isVisible !== this.props.isVisible){
        this.setState({isVisible: nextProps.isVisible});
    }
}

  render() {
    const { children, className } = this.props;
    if (!this.state.isVisible) {
      return null;
    }

    return (
      <div className={className}>
        {children}
        <button className="alert__dismiss" type="button" onClick={() => this.toggle()}>
          <MdClear />
        </button>
      </div>
    );
  }
};


export default Alert
