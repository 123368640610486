import { DELETE_UPLOAD_PENDING, DELETE_UPLOAD_FULFILLED, DELETE_UPLOAD_REJECTED } from './actions';

export const reducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case DELETE_UPLOAD_PENDING:
      return {
        ...state,
        isFetching: true
      }
    case DELETE_UPLOAD_FULFILLED:
      return {
        ...state,
        isFetching: false,
      }
    case DELETE_UPLOAD_REJECTED:
      return {
        ...state,
        isFetching: false
      }
    default:
      return state
  }
};
