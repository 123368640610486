import { SubmissionError } from 'redux-form';
import { authErrorMessages } from './authErrorCodes';

const defaultValue = { data: {} }

const handleError = (err = defaultValue) => {
  const { errorCode = 99 } = err.data;
  const errors = {};

  for (const { errorCode: code, errorMessage, errorField = '_error' } of authErrorMessages) {
    if (errorCode === code) {
      /* submit field level error if field is present */
      errors[errorField] = errorMessage;
    }
  }

  throw new SubmissionError(errors);
}

export default handleError;
