import axios from 'axios';
import Auth0 from '../../../../../store/actions/auth/Auth';
import { push } from 'connected-react-router'

import handleError from '../../../../../components/FormHelpers/handleError';

const MAX_FILE_SIZE_MB = 300;
const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1000 * 1000;

const postFile = (file, deliveryId) => {
  let fileToBeSent = new FormData();
  fileToBeSent.append("file", file, file.name )

  return axios.post(`/api/files?deliveryId=${deliveryId}`, fileToBeSent, {
    onUploadProgress: (progressEvent) => {
      console.log(( 100 * progressEvent.loaded ) / progressEvent.total + '%');
    },
    headers: { 'Content-Type': 'multipart/form-data', "Authorization": `Bearer ${Auth0.getAccessToken()}`}
  })
};
  
const validateFile = (file) => {
  return new Promise((resolve) => {
    if(file.size > MAX_FILE_SIZE) {
      console.error("File size is too big");
      throw new Error("File size is too big"); //TODO: Translate
    }
    return resolve();
  })
}

const submitForm = async (values, dispatch) => {
  let req1 = {}

  const arrayToPost = {
    ...values,
    numberOfFiles: values.fileDrop.length
  };
  delete arrayToPost['fileDrop'];
  
  try {
    req1 = await axios.post(`/api/admin/deliveries/fromCustomer`, {
      ...arrayToPost
    }, {
      headers: { "Authorization": `Bearer ${Auth0.getAccessToken()}` }
    })
  } catch (err) {
    return handleError(err.response)
  }

  let promises = [];

  values.fileDrop.forEach((file) => {
    promises.push(validateFile(file), postFile(file, req1.data.deliveryId));
  });

  return Promise.all(promises)
    .then( () => {
      axios.post(`/api/admin/deliveries/fromCustomer/${req1.data.deliveryId}/uploadComplete`, {}, {
        headers: { "Authorization": `Bearer ${localStorage.getItem('access_token')}` } // TODO: User auth function for this
      })
      dispatch(push('/uploadconfirmed'));
    })
    .catch(err => handleError(err.response));
};

export default submitForm;


