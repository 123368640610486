import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { message } from "antd";
import { MdEmail } from 'react-icons/md';
import DeliveryLayout from "../../components/Layout/DeliveriesLayout";
import { actionCreators as userActionCreators } from "../../data/user/actions";

class VerifyPage extends Component {
  componentDidMount() {
    if (this.props.emailVerified) {
      this.props.history.push("/");
    } else {
      this.props.refreshSession();
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.emailVerified && !prevProps.emailVerified) {
      await message.success("E-post verifierad!", 3);
      this.props.history.push("/");
    }
  }

  render() {
    const {
      email, refreshSession, emailVerified, isLoading, t,
    } = this.props;

    return (
      <DeliveryLayout>
        <div className='verify__wrapper'>
          <div className='verify__contentWrapper'>
            <MdEmail className='verify__icon' />
            <h1 className='verify__title'>{t('title')}</h1>
            <p className='verify__description'>
              {t('description1')} ({email}) {t('description2')}
            </p>
            <button
              onClick={refreshSession}
              className="verify__button btn btn--primary"
              disabled={emailVerified || isLoading}
            >
              {emailVerified ? t('button.success') : t('button.fail')}
            </button>
          </div>
        </div>
      </DeliveryLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  email: state.user.clientInfo.email,
  emailVerified: state.user.clientInfo.email_verified,
  isLoading: state.user.isLoading,
});

const mapActionCreators = {
  ...userActionCreators,
};

const withRedux = connect(mapStateToProps, mapActionCreators);

export default compose(
  withRedux,
  withRouter,
  withTranslation('verify')
)(VerifyPage);

