import axios from 'axios';
import handleError from '../../../../../../../../../../../components/FormHelpers/handleError';
import Auth0 from '../../../../../../../../../../../store/actions/auth/Auth';
import { actionCreators } from '../../../../../../../../../data/upload/actions';

const submitForm = (values, dispatch, props) => {
  console.log(props)
  return axios.patch(`/api/admin/deliveries/fromCustomer/${props.uploadData.id}`, {
    ...props.uploadData,
    ...values,
    status: 'Approved'
  }, { headers: { "Authorization": `Bearer ${Auth0.getAccessToken()}` } })
  .then( (response) => {
    dispatch(actionCreators.setSingleUploadData(response));
  })
  .catch( (err) => handleError(err.response) );
};

export default submitForm;
