import axios from 'axios';
import Auth0 from '../../../../store/actions/auth/Auth'

export const GET_SUPPLIERS = 'GET_SUPPLIERS';
export const GET_SUPPLIERS_PENDING = 'GET_SUPPLIERS_PENDING';
export const GET_SUPPLIERS_FULFILLED = 'GET_SUPPLIERS_FULFILLED';
export const GET_SUPPLIERS_REJECTED = 'GET_SUPPLIERS_REJECTED'
export const SELECT_SUPPLIER = 'SELECT_SUPPLIER';

export const actionCreators = {
  getSuppliers: () => async (dispatch) => {
    dispatch({
      type: GET_SUPPLIERS,
      payload: axios.get('api/admin/ExternalDataSupplier', { headers: { "Authorization": `Bearer ${Auth0.getAccessToken()}` } })
    })
  },
  selectSupplier: (supplierId) => {
    return {
      type: SELECT_SUPPLIER,
      payload: supplierId
    }
  }
};
