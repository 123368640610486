import { combineReducers } from 'redux';

import { reducer as ListReducer } from './scenes/List/reducer';
import { reducer as DataReducer } from './data/reducer';

export const reducer = combineReducers({
  data: DataReducer,
  scenes: combineReducers({
    list: ListReducer,
  })
});
