import { SET_USER_AUTH0_DATA, CLEAR_USER_DATA, SET_USER_LOADING } from "./actions";

export const reducer = (state = { isLoading: false, clientInfo: {} }, action) => {
  if (action.type === SET_USER_AUTH0_DATA) {
    return {
      ...state,
      clientInfo: action.payload,
    };
  }
  if (action.type === CLEAR_USER_DATA) {
    return {
      isLoading: false,
      clientInfo: {},
    };
  }
  if (action.type === SET_USER_LOADING) {
    return {
      ...state,
      isLoading: action.payload,
    }
  }

  return state;
};