import { 
  SET_DELIVERY_MESSAGE,
  DELIVERY_MESSAGE
} from './actions'

export const reducer = (state = { data: DELIVERY_MESSAGE.sv }, action) => {
  switch (action.type) {
    case SET_DELIVERY_MESSAGE:
      return {
        ...state,
        data: action.data
      }
    default:
      return state
  }
}
