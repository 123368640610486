import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import List from './scenes/List';
import Show from './scenes/Show';
import AdminLayout from '../../../../components/Layout/AdminLayout';

const Uploads = ({ match: { url } }) => (
  <AdminLayout title="Kunduppladdningar" back>
    <Route path={`${url}`} component={List} exact />
    <Route path={`${url}/:id`} component={Show}/>
  </AdminLayout>
);

export default withRouter(Uploads);
