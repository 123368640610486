import { GET_UPLOADS_LIST_PENDING, GET_UPLOADS_LIST_FULFILLED, GET_UPLOADS_LIST_REJECTED, DELETE_UPLOAD_PENDING, DELETE_UPLOAD_FULFILLED, DELETE_UPLOAD_REJECTED } from './actions';

export const reducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case GET_UPLOADS_LIST_PENDING:
      return {
        ...state,
        isFetching: true
      }
    case GET_UPLOADS_LIST_FULFILLED:
      return {
        ...state,
        isFetching: false,
        hasError: false,
        data: action.payload.data.deliveryInfoList,
        pages: action.payload.data.numberOfPages
      }
    case GET_UPLOADS_LIST_REJECTED:
      return {
        ...state,
        isFetching: false,
        hasError: true
      }
    case DELETE_UPLOAD_PENDING:
      return {
        ...state,
        isFetching: true
      }
    case DELETE_UPLOAD_FULFILLED:
      return {
        data: state.data.filter(upload => upload.deliveryId !== action.deliveryId),
        isFetching: false,
      }
    case DELETE_UPLOAD_REJECTED:
      return {
        ...state,
        isFetching: false
      }
    default:
      return state
  }
};
