import React from 'react';

const CustomerGdprData = ({ gdprData: { origin, purpose, noOfEntities, description, type }, s }) => (
  <div className={s["gdpr-accepted-form"]}>
    <p>Ursprung:</p> <strong>{origin}</strong>
    <p>Typ av uppgift:</p> <strong>{type}</strong>
    <p>Syfte:</p> <strong>{purpose}</strong>
    <p>Antal poster:</p> <strong>{noOfEntities}</strong>
    <p>Beskrivning:</p> <strong>{description}</strong>
  </div>
)

export default CustomerGdprData;
