import 'url-search-params-polyfill';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, change, touch } from 'redux-form';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import validateForm from './components/validateForm';
import submitForm from './components/submitForm';
import Form from './components/Form';
import { actionCreators } from '../../actions';

class FindDelivery extends Component {
  componentDidMount() {
    const queryParams = new URLSearchParams(this.props.location.search);
    const deliveryId = queryParams.get('id');
    if (deliveryId) {
      this.props.dispatch(change('findDeliveryForm', 'deliveryId', queryParams.get('id')));
      this.props.dispatch(touch('findDeliveryForm', 'deliveryId'));
      this.props.getUserPermissions(deliveryId);
    }

  }
  render() {
    const { next } = this.props;
    return (
      <Form {...this.props} submitForm={values => submitForm(values, next, this.props.dispatch)} />
    )
  }
}

export { FindDelivery }

export default compose(
  withRouter,
  connect(
    state => state.deliveries,
    dispatch => ({
      ...bindActionCreators(actionCreators, dispatch), dispatch
    })
  ),
  reduxForm({
    form: 'findDeliveryForm',
    validate: validateForm
  })
)(FindDelivery);
