import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const isActive = (link) => (match, { pathname }) => {
  if (link === '/') return (pathname.startsWith('/delivery')) /* only exception */
  return match;
}

const NavigationItem = ({ item: { link, label } }) => {
  const { t } = useTranslation('pageHeader');
  return (
    <li className="navigation__element">
      <NavLink to={link} isActive={isActive(link)} className="navigation__link" activeClassName="navigation__link--active">{t(label)}</NavLink>
    </li>
  )
};

export default NavigationItem;
