import { 
  FIND_RECIPENTS_PENDING,
  FIND_RECIPENTS_FULFILLED,
  FIND_RECIPENTS_REJECTED,
  AUTOFILL_SELECTED_RECIPIENT,
  CLEAR_RECIPIENTS,
  CLEAR_SELECTED_RECIPIENT,
  CLEAR_SINGLE_RECIPIENT,
} from './actions'
import { recipientsMapper } from '../../../../../../../../helpers';

export const reducer = (state = { data: [], selectedRecipients: [] }, action) => {
  switch (action.type) {
    case FIND_RECIPENTS_PENDING:
      return {
        ...state,
        isFetching: true
      }
    case FIND_RECIPENTS_FULFILLED:
      return {
        ...state,
        isFetching: false,
        data: recipientsMapper(action.payload.data),
      }
      case FIND_RECIPENTS_REJECTED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
      }
      case AUTOFILL_SELECTED_RECIPIENT:
        const { recipient, index } = action.payload;
        let newState = { ...state };
      
        if (newState.selectedRecipients[index]) {
          newState.selectedRecipients[index] = recipient;
        } else {
          newState.selectedRecipients = [...newState.selectedRecipients, recipient];
        }
        return newState;
      case CLEAR_RECIPIENTS:
        return {
          ...state,
          data: [],
        }
      case CLEAR_SELECTED_RECIPIENT:
        return {
          ...state,
          selectedRecipients: [],
        }
      case CLEAR_SINGLE_RECIPIENT:
        return {
          ...state,
          selectedRecipients: state.selectedRecipients.filter((_, index) => index !== action.payload)
        }
    default:
      return state
  }
}
