import axios from 'axios';
import Auth0 from '../../../../../../../../store/actions/auth/Auth'
import { actionCreators as fileProgressActionCreators } from '../../../../../../../../data/fileProgress/actions';

export const GET_DOCUMENTS = 'GET_DOCUMENTS';
export const GET_DOCUMENTS_PENDING = 'GET_DOCUMENTS_PENDING';
export const GET_DOCUMENTS_FULFILLED = 'GET_DOCUMENTS_FULFILLED';
export const GET_DOCUMENTS_REJECTED = 'GET_DOCUMENTS_REJECTED';

const { setUploadStatus, setUploadProgress, setUploadData, setUploadComplete } = fileProgressActionCreators;

export const actionCreators = {
  getDocuments: () => async (dispatch) => {
    dispatch({
      type: GET_DOCUMENTS,
      payload: axios.get('api/admin/documents', {headers: { "Authorization": `Bearer ${Auth0.getAccessToken()}` }})
    })
  },
  postFile: (file, deliveryId) => async (dispatch) => new Promise(async (resolve, reject) => {
    let fileToBeSent = new FormData();
    fileToBeSent.append("file", file, file.name )
  
    try {
      dispatch(setUploadData({ 
        name: file.name, 
        deliveryId,
        file,
      }))

      dispatch(setUploadStatus({ 
        name: file.name,
        status: 'active',
      }))
  
      const response = await axios.post(`/api/files?deliveryId=${deliveryId}`, fileToBeSent, {
        onUploadProgress: (progressEvent) => {
          dispatch(setUploadProgress({
            name: file.name,
            progress: parseInt((100 * progressEvent.loaded) / progressEvent.total)
          }))
        },
        headers: { 'Content-Type': 'multipart/form-data', "Authorization": `Bearer ${Auth0.getAccessToken()}`}
      })

      
      dispatch(setUploadStatus({ name: file.name, status: 'success' }))
      resolve(response)
    } catch (err) {
      dispatch(setUploadStatus({ name: file.name, status: 'exception' }))
      reject(err)
    }
  }),
  uploadComplete: (deliveryId) => async (dispatch, getState) => {
    const { fileProgress } = getState()
    const allFilesUploaded = Object.values(fileProgress)
      .filter(Boolean)
      .every(({ status }) => status === 'success')

    if (allFilesUploaded) {
      try {
        await axios.post(`/api/admin/deliveries/toCustomer/${deliveryId}/uploadComplete`, {}, {
          headers: { "Authorization": `Bearer ${Auth0.getAccessToken()}` }
        })
      
        dispatch(setUploadComplete(true));
        
      } catch (err) {

      }
    }
  }
};
