import { 
  GET_SUPPLIERS_PENDING,
  GET_SUPPLIERS_FULFILLED,
  GET_SUPPLIERS_REJECTED,
  SELECT_SUPPLIER
} from './actions'

export const reducer = (state = { values: [ ], selectedSuppliers: [ ] }, action) => {
  switch (action.type) {
    case GET_SUPPLIERS_PENDING:
      return {
        ...state,
        isFetching: true,
      }
    case GET_SUPPLIERS_FULFILLED:
      return {
        ...state,
        values: action.payload.data.map(x => { return { label: x.name, value: x.id } }),
        isFetching: false,
        hasFiles: true
      }
    case GET_SUPPLIERS_REJECTED:
      return {
        ...state,
        isFetching: false,
        hasError: true
      }
    case SELECT_SUPPLIER:
      return {
        ...state,
        selectedSuppliers: action.payload
      }
    default:
      return state
  }
}
