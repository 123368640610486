import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';
import logger from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { reducer as formReducer } from 'redux-form'
import { reducer as deliveryReducer } from '../scenes/Customer/scenes/Deliveries/reducer';
import { reducer as customerUploadReducer } from '../scenes/Customer/scenes/Upload/reducer';
import { reducer as adminReducer } from '../scenes/Admin/reducer';
import { reducer as fileProgressReducer } from '../data/fileProgress/reducer';
import { reducer as userReducer } from '../data/user/reducer';
import { authReducer, callbackReducer } from "mise-react-redux-auth0";

const persistConfig = {
  key: 'root',
  storage,
}

export default function configureStore(history, initialState) {
  const reducers = {
    form: formReducer,
    Admin: adminReducer,
    deliveries: deliveryReducer,
    customerUpload: customerUploadReducer,
    fileProgress: fileProgressReducer,
    user: userReducer,
    auth: authReducer,
    callback: callbackReducer,
  };

  const middleware = [
    thunk,
    routerMiddleware(history),
    promiseMiddleware(),
  ];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  const isDevelopment = process.env.NODE_ENV === 'development';

  if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
    enhancers.push(window.devToolsExtension());
  }

  if (isDevelopment) {
    middleware.push(logger);
  }

  const rootReducer = combineReducers({
    ...reducers,
    router: connectRouter(history)
  });

  const persistedReducer = persistReducer(persistConfig, rootReducer)

  return createStore(
    persistedReducer,
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  );
}

export function persistor(store) {
  return persistStore(store);
}
