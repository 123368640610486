import React from 'react';
import classNames from 'classnames/bind';
import styles from './badge.module.sass';

let cx = classNames.bind(styles);

const Badge = ({type, children}) => {
  let badgeClass = cx({
    badge: true,
    'badge--primary': type === 'primary',
    'badge--warning': type === 'warning',
    'badge--success': type === 'success'
  });

  return (
    <div className={badgeClass}>
      {children}
    </div>
  )
}

export default Badge;
