import React, { Component } from 'react';
import { connect } from 'react-redux';
import SelectableCard from '../SelectableCard/SelectableCard';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../data/Documents/actions';

class Terms extends Component {

  componentDidMount() {
    this.props.getDocuments();
  }

  render() {
    const { isFetching, documents, error } = this.props;
    
    if (error) {
      return <div>Ett fel uppstod vid hämtning av köpvillkor.</div>;
    }

    if (isFetching) {
      return <div>Laddar köpvillkor...</div>;
    }

    return (
      <SelectableCard name="documents" options={documents} />
    )
  }
}

export default connect(
  state => ({
    documents: state.Admin.scenes.Deliveries.scenes.new.data.documents.data.map(x => { return { label: x.title, value: x.id } }),
    isFetching: state.Admin.scenes.Deliveries.scenes.new.data.documents.isFetching,
    error: state.Admin.scenes.Deliveries.scenes.new.data.documents.hasError
  }),
  dispatch => ({
    ...bindActionCreators(actionCreators, dispatch), dispatch
  })
)(Terms);
