import React from 'react';
import { Field } from 'redux-form';
import horizontalFormInput from '../../../../../../../../components/FormHelpers/horizontalFormInput';
import PrefillDropdown from './components/PrefillDropdown';

const Message = ({loadDeliveryMessage}) => (
  <div className="card">
    <div className="card-header">
      <span className="card-header__title">Leveransmeddelande till mottagare</span>
    </div>

    <div className="card__body form">
      <Field component={horizontalFormInput} renderAs="textarea" className="form-control" name="deliveryMessage" label="Meddelande" rows="4" maxlength="2000" />

      <Field component="input" type="checkbox" name="sendWithoutTemplate" id="sendWithoutTemplate" /> <label htmlFor="sendWithoutTemplate">Skicka mail obrandat (helt vitt, enbart text)</label>
      <PrefillDropdown loadDeliveryMessage={loadDeliveryMessage} />
    </div>

    {/* <div className="card__footer card__footer--right">
      <button className="btn" type="button">Förhandsgranska brev</button>
    </div> */}

  </div>
);

export default Message;
