const validate = ( values, props ) => {
  const { t } = props
  const errors = {}

  if (!values.fileDrop || !values.fileDrop.length) {
    errors.fileDrop = { _error: t('files.validate.errorMessage') }
  } 

  return errors
}

export default validate;
