import React from 'react';
import { reduxForm, formValueSelector } from 'redux-form'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../actions';
import { compose } from 'recompose'
import validate from './components/validate';
import Alert from '../../../../../../components/Alert/Alert';
import Form from './components/Form';
import submitForm from '../../components/submitForm';
import s from './Recipient.module.sass';

class Recipient extends React.Component {
  componentDidMount() {
    this.props.getRecipients();
    
  }
  render() {
    const { submitFailed, error } = this.props;
    return (
      <>
        <Form s={s} submitForm={submitForm} {...this.props} />
        <Alert className="alert alert--danger" isVisible={submitFailed}>{error}</Alert>
      </>
    );
  }
}

const selector = formValueSelector('customer-upload')

export default compose(
  connect(
    state => {
      const customerUpload = state.customerUpload;
      const hasSpecificRecipient = selector(state, 'specificRecipient')
      return {
        hasSpecificRecipient,
        ...customerUpload
      }
    },
    dispatch => ({
      ...bindActionCreators(actionCreators, dispatch), dispatch
    })
  ),
  reduxForm({
    form: 'customer-upload',
    validate: validate,
    forceUnregisterOnUnmount: true
  })  
)(Recipient);

