import React from 'react';
import PageHeader from './PageHeader/PageHeader';
import { customerMenu } from './helpers/navigationData';

const MainLayout = ({ title, children, userCanUploadFiles }) => (
  <>
    <PageHeader title={title} navItems={customerMenu} userCanUploadFiles={userCanUploadFiles} />
    <main>
      {children}
    </main>
  </>
);

export default MainLayout;
