import AuthConstructor from "mise-react-redux-auth0";

const Auth0 = new AuthConstructor({
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientID: process.env.REACT_APP_AUTH0_CLIENTID,
  audience: process.env.REACT_APP_AUTH0_APIAUDIENCE,
  redirectUri: process.env.REACT_APP_AUTH0_CALLBACKURL,
  returnTo: process.env.REACT_APP_SIGNOUT_RETURNURL,
  scope: 'openid profile email update:password deliveries:send-to-customer deliveries:receive-from-customer deliveries:upload-files deliveries:send-from-customer',
  responseType: 'token id_token'
});

export default Auth0;
