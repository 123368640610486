import axios from 'axios';
import Auth0 from '../../../../../../../../store/actions/auth/Auth'
import { toast } from 'react-toastify';

export const GET_UPLOADS_LIST = 'GET_UPLOADS_LIST';
export const GET_UPLOADS_LIST_PENDING = 'GET_UPLOADS_LIST_PENDING';
export const GET_UPLOADS_LIST_FULFILLED = 'GET_UPLOADS_LIST_FULFILLED';
export const GET_UPLOADS_LIST_REJECTED = 'GET_UPLOADS_LIST_REJECTED';
export const DELETE_UPLOAD = 'DELETE_UPLOAD';
export const DELETE_UPLOAD_PENDING = 'DELETE_UPLOAD_PENDING';
export const DELETE_UPLOAD_FULFILLED = 'DELETE_UPLOAD_FULFILLED';
export const DELETE_UPLOAD_REJECTED = 'DELETE_UPLOAD_REJECTED';

export const actionCreators = {
  getFileList: (data) => async (dispatch) => {
    dispatch({
      type: GET_UPLOADS_LIST,
      payload: axios.get('/api/admin/deliveries/fromCustomer', {
        params: {
          pageSize: data.pageSize || 25,
          page: (data.page + 1) || 1
        },
        headers: { "Authorization": `Bearer ${Auth0.getAccessToken()}` }
      })
    })
  },
  deleteUpload: (deliveryId) => async (dispatch) => {
    dispatch({
      type: DELETE_UPLOAD_PENDING,
    })
    axios.delete(`/api/admin/deliveries/fromCustomer/${deliveryId}`, {
      headers: { "Authorization": `Bearer ${Auth0.getAccessToken()}` }
    }).then( () => {
      dispatch({
        type: DELETE_UPLOAD_FULFILLED,
        deliveryId: deliveryId,
      })
      toast.success('Leveransen har raderats');
    }).catch( err => {
      dispatch({
        type: DELETE_UPLOAD_REJECTED,
        error: err
      })
      toast.error('Leveransen kunde inte raderas. Kontakta supporten om felet kvarstår.');
    })
  }
};
