import { GET_RECIPIENTS_PENDING, GET_RECIPIENTS_FULFILLED, GET_RECIPIENTS_REJECTED } from './actions'

export const reducer = (state = { recipients: [ ], hasRecipients: false }, action) => {
  switch (action.type) {
    case GET_RECIPIENTS_PENDING:
      return {
        ...state,
        isFetching: true,
      }
    case GET_RECIPIENTS_FULFILLED:
      return {
        ...state,
        recipients: action.payload.data,
        isFetching: false,
        hasRecipients: true
      }
    case GET_RECIPIENTS_REJECTED:
      return {
        ...state,
        isFetching: false,
        hasError: true
      }
    default:
      return state
  }
}
