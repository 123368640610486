import React from 'react';
import { Field } from 'redux-form'
import renderSelection from './renderSelection';
import { useTranslation } from 'react-i18next';

const Form = ({ handleSubmit, submitForm, previous, hasError, recipients, isFetching, hasRecipients, hasSpecificRecipient, submitting, syncErrors, s }) => {
  const { t } = useTranslation('upload');
  return (
  <form onSubmit={handleSubmit(submitForm)}>
    <h4 className={s['header']}>{t('recipient.form.header')}</h4>
    <p>{t('recipient.form.text')}</p>
      { !hasSpecificRecipient && <div>{t('recipient.form.recipient1')}<strong>{t('recipient.form.recipient2')}</strong></div> }
      {isFetching && hasSpecificRecipient &&
        <span>{t('recipient.form.loading')}</span>
      }
      {hasRecipients && hasSpecificRecipient &&
        <Field name="recipient" component={renderSelection} options={recipients} />
      }
      {hasError && hasSpecificRecipient &&
        <div>{t('recipient.form.error')}</div>
      }
    <div className={s['recipient-checkbox']}>
      <Field
        name="specificRecipient"
        id="specificRecipient"
        component="input"
        type="checkbox"
      />
      {' '}<label htmlFor="specificRecipient">{t('recipient.form.label')}</label>
    </div>
    <div className={s['footer']}>
      <button className="btn btn--transparent" type="button" onClick={previous}>{t('recipient.form.returnButton')}</button>
      <button type="submit" className="btn btn--primary" disabled={submitting || isFetching || hasError || syncErrors}> {/* TODO: Force value on dropdown before enabling button */}
        {t('recipient.form.submitButton')}
      </button>
    </div>
  </form>
  );
}

export default Form;
