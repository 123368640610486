import { combineReducers } from 'redux';

import { reducer as documentsReducer } from './Documents/reducer';
import { reducer as recipientsReducer } from './Recipients/reducer';
import { reducer as messageReducer } from './Message/reducer';

export const reducer = combineReducers({
  documents: documentsReducer,
  recipients: recipientsReducer,
  message: messageReducer
});
