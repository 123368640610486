import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import submitForm from './components/submitForm';
import Form from './components/Form'

class Authenticate extends Component {
  render() {
    const { next } = this.props;
    return(
      <Form {...this.props} submitForm={values => submitForm(values, next)} />
    );
  }
}

export default Authenticate = reduxForm({
  form: 'authCodeForm',
  initialValues: {
    codeInputs: [ {}, {}, {}, {}, {}, {} ]
  }
})(Authenticate); 
