import React from 'react';
import ReactLoading from 'react-loading';
import s from './Loading.module.sass';
 
const LoadingScreen = () => (
  <div className={s['loading-screen']}>
    <ReactLoading type="spin" color="#095587" />
    <p>Laddar...</p>
  </div>
);
 
export default LoadingScreen;
