import React from 'react';
import { Field } from 'redux-form';
import renderField from './renderField';
import { useTranslation } from 'react-i18next';

const Form = ({ handleSubmit, pristine, submitting, invalid, error, submitForm }) => {
  const { t } = useTranslation('deliveries');
  return (
    <form onSubmit={ handleSubmit(submitForm) } className="order-details">
      <div className="order-details__content">

        <h3>{t('findDelivery.form.header')}</h3>
        <div className="form-row">
          <Field
            name="deliveryId"
            component={renderField}
            label={t('findDelivery.form.label1')}
            type="tel"
            placeholder={t('findDelivery.form.placeholder1')}
            t={t}
          />
        </div>

        <div className="form-row">
          <Field
            name="mobileNo"
            id="mobileNo"
            component={renderField}
            label={t('findDelivery.form.label2')}
            type="tel"
            placeholder="07X - XXX XX XX"
            t={t}
          />
        </div>

        {error && <span className="help-text help-text--has-error">{ error }</span>}

        <button className="btn btn--primary btn--block" type="submit" disabled={ pristine || submitting || invalid }>
          {t('findDelivery.form.button')}
        </button>

      </div>

    </form>
  )
  }

export default Form;

