const validate = ( values, props ) => {
  const { t } = props;
  const errors = {}

  if (!values.recipient) {
    errors.recipient = t('recipient.validate.error')
  }
  return errors
}

export default validate;
