import axios from 'axios';
import { saveFile } from '../../../../../../../../../../helpers/'

const getFile = fileId => {
  return axios.get(`api/files/${fileId}`, {
    responseType: 'blob'
  })
  .then( response => {
    saveFile(response);
  })
  .catch(function (error) {
    alert('Hämtning av fil misslyckades.') //TODO: Does our API expose reasons for download failure so we can display them to user? //TODO: Translate
    console.log(error);
  });
}

export default getFile;
